import {
    login,
    addVessel,
    getVessel,
    updateVessel,
    vesselTypes,
    allCountries,
    delVessel,
    addRestHours,
    getRestHours,
    getCustomer,
    delCustomer,
    updateCustomer,
    generateSignUrl,
    getVesselAdmins,
    createVesselAdmins,
    deleteVesselAdmin,
    updateVesselAdmin,
    forgetPassword,
    resetPassword,
    createVesselUser,
    getVesselUsers,
    updateVesselUser,
    uploadRestHours,
  } from './constants'
  import axiosInstance from './interceptor'
  
  export const loginapi = (payload) => {
    return axiosInstance.post(login, payload)
  }
  
  export const getVesselApi = (payload) => {
    return axiosInstance.get(getVessel, { params: payload })
  }
  
  export const createVesselApi = (payload) => {
    return axiosInstance.post(addVessel, payload)
  }
  
  export const delVesselApi = (payload) => {
    return axiosInstance.delete(delVessel, { params: payload })
  }
  export const updateVesselApi = (payload, params) => {
    return axiosInstance.put(updateVessel, payload, { params: params })
  }
  
  export const getVesselTypeApi = () => {
    return axiosInstance.get(vesselTypes)
  }
  export const getCountriesApi = () => {
    return axiosInstance.get(allCountries)
  }
  
  export const postRestHoursApi = (payload) => {
    return axiosInstance.post(addRestHours, payload)
  }

  export const uploadResthour=(payload)=>{
    return axiosInstance.post(uploadRestHours,payload)
  }
  
  export const getRestHoursApi = (payload) => {
    return axiosInstance.get(getRestHours, { params: payload })
  }
  
  export const getCustomerApi = (payload) => {
    return axiosInstance.get(getCustomer, { params: payload })
  }
  
  export const updateCustApi = (payload, params) => {
    return axiosInstance.put(updateCustomer, payload, { params: params })
  }
  
  export const DelCustomerApi = (payload) => {
    return axiosInstance.delete(delCustomer, { params: payload })
  }
  
  export const generateSignUrlApi = (payload) => {
    return axiosInstance.post(generateSignUrl, payload)
  }
  
  export const getVesselAdminsApi = (params) => {
    return axiosInstance.get(getVesselAdmins,{ params: params })
  }
  export const createVesselAdminsApi = (payload) => {
    return axiosInstance.post(createVesselAdmins, payload)
  }
  export const delVesselAdminsApi = (params) => {
    return axiosInstance.delete(deleteVesselAdmin, { params: params })
  }
  
  export const updateVesselAdminApi=(payload, params)=>{
    return axiosInstance.put(updateVesselAdmin,payload,{params:params} )
  }
  export const forgetPasswordApi = (payload) => {
    return axiosInstance.post(forgetPassword, payload)
  }
  export const passwordResetApi = (payload) => {
    return axiosInstance.put(resetPassword, payload)
  }
  
  export const getVesselUsersApi=(params)=>{
    return axiosInstance.get(getVesselUsers,{params:params})
  }

  export const createVesseluserApi = (payload) => {
    return axiosInstance.post(createVesselUser, payload)
  }
  export const updateVesseluserApi=(payload, params)=>{
    return axiosInstance.put(updateVesselUser,payload,{params:params} )
  }