import React, { useState, useEffect } from "react";
import { Edit, Trash2, Eye, EyeOff, Download, Archive, ArrowLeftCircle, PlusCircle } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Spinner from "react-bootstrap/Spinner";
import Pagination from 'react-bootstrap/Pagination';
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import {
  DelCustomerApi,
  getVesselAdminsApi,
  getVesselApi,
  createVesselAdminsApi,
  delVesselAdminsApi,
  updateVesselAdminApi,
  getVesselUsersApi,
  generateSignUrlApi,
} from "../../Common/Api/index";
import { downloadAsJSON } from "../../Common/Functions/CommonFunctions";
import EditVesselAdmin from "./EditVesselAdmin";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
const { Buffer } = require('buffer');


const apiurl = process.env.REACT_APP_API_URL;

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Name is required !"),
    rank: yup.string().required("Rank is required !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    username: yup.string().required("Username is required !"),
    vessel: yup.array().min(1, "Required").required("Please select vessel !"),
    //adminEmail: yup.string().required('Email is required !').email('Please enter valid email !'),
    // password: yup
    //   .string()
    //   .required("Password is required !")
    //   .min(6, "Minimum 6 characters required !")
    //   .max(16, "Maximum 16 characters allowed !")
    //   .matches(/^((?!\s).)*$/, "Space not allowed"),
    //  signature:yup.mixed()
    //  .test(
    //    'fileSize',
    //    'Signature  is required, Max size is 5MB !',
    //    (value) => !value || (value && value[0]?.size <= 5 * 1024 * 1024)
    //  )
    //  .required('Signature  is required'),
    
  })
 
  .required();

function VesselAdminMaster() {

  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState({ data: [], loading: false });
  const [countries, setCountries] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [sameAsEmail, setSameAsEmail] = useState(false);
  const [selectedVessel, setSeletedVessel] = useState([]);

  const [vesselList, setVesselList] = useState({ data: [], loading: false });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({
    maxPage: "",
    totalCount: "",
  });


  //hook to check error

  const [email, setEmail] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  //const [user_List, setUser_List] = useState('');

  const [user_List, setUser_List] = useState([])

  const token = localStorage.getItem("token");
  const loginData = JSON.parse(localStorage.getItem("loginData"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail) {
      const email = getValues("email");
      setValue("username", email);
    }
  }, [sameAsEmail]);

  useEffect(() => {
    if (visible == true) {
      reset();
    }
  }, [visible]);

  useEffect(() => {
    fetchUsers(page);
    fetchVessels();
  }, []);

  function fetchVessels() {
    setVesselList({ ...vesselList, data: [], loading: true });
    getVesselApi({ customer_id: loginData.user_id ,limit: 10000, offset:0}).then(
      (res) => {
        console.log("res", res);
        let  data = res.data.data;
        console.log("data", data);
         let ves_data=data.response.results.filter((item)=>item.status==true);
          data.response.results=ves_data
        if (data.statusCode == 200) {
          setVesselList({
            ...vesselList,
            data: data.response.results,
            loading: false,
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const fetchUsers = (p, ves, searchVal) => {
    setUsers({ ...users, data: [], loading: true });

    const params = { limit: 10, offset: p - 1, search: searchVal };
    if (ves) {
      console.log("ves", ves);
    }
    console.log("params",params)

    getVesselAdminsApi(params).then(
      (res) => {
        console.log("ressd", res);
        if (res.data.data.statusCode == 200) {
          setMaxPageAndTotalCount({
            ...maxPageAndTotalCount,
            maxPage: calMaxPage(res.data.data.response.count.totalData),
            totalCount: res.data.data.response.count.totalData,
          });
          if (res.data.data.response.results.length > 0) {
            setUsers({
              ...users,
              loading: false,
              data: res.data.data.response.results,
            });
          } else {
            setUsers({ ...users, loading: false, data: [] });
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };



  async function download_main_json(val) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));

    val.temp_password = "abcd1234";
    val.reset_password = "false";
    val.customerId = loginData.user_id;
   // val.customerLogo = loginData.logo;
    console.log('val', val);

  
    try {
      // Fetch the content of the URL
      const response = await axios.get(loginData.logo, { responseType: 'arraybuffer' });
  
      // Convert the array buffer to base64
      const base64Data = Buffer.from(response.data, 'binary').toString('base64');
  
      console.log('Base64 representation:', base64Data);
      val.customerLogo=base64Data;
   



    try {
      const res = await getVesselUsersApi({limit:200,vesselid:val.vesselid,offset:0}); 

      console.log("ressd", res);

      if (res.data.data.statusCode === 200) {
        let users_data = res.data.data.response.results.filter((item) => item.vesselid === val.vesselid);

        console.log("user",users_data)
        if (users_data.length > 0) {
           
          for (let i = 0; i < users_data.length; i++) {
            const loginData = JSON.parse(localStorage.getItem('loginData'))

            users_data[i].temp_password = "abcd1234";
            users_data[i].reset_password = "false";
            users_data[i].customerId = loginData.user_id
          }

          console.log("users_data", users_data);
          for(let i=0; i<users_data.length; i++){
            users_data[i].signature="";
          }
          val.signature="";
          const resPayload = {
            ...val,
            vessel_users: users_data
          }
         


          console.log("resPayload", resPayload);

          if (Object.keys(resPayload).length > 0) {
            const json = await JSON.stringify(resPayload)
            // console.log('json', json)
            const blob = new Blob([json], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            const fileName = resPayload.name + '_' + resPayload.vesselname + '.json'
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
            URL.revokeObjectURL(url)
          } else {
            toast.error(`Something went wrong`, {
              autoClose: 2000
            })
          }
           
        }
        else{
          const resPayload = {
            ...val,
            vessel_users: []
          }
         


          console.log("resPayload", resPayload);

          if (Object.keys(resPayload).length > 0) {
            const json = await JSON.stringify(resPayload)
            // console.log('json', json)
            const blob = new Blob([json], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            const fileName = resPayload.name + '_' + resPayload.vesselname + '.json'
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
            URL.revokeObjectURL(url)
          } else {
            toast.error(`Something went wrong`, {
              autoClose: 2000
            })
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      toast.error(`Something went wrong`, {
        autoClose: 2000
      })
    }
  } catch (error) {
    console.error('Error fetching URL:', error.message);
    toast.error(`Something went wrong`, {
      autoClose: 2000
    })
  }
  }




   
  
  async function downloadUserJSON(val) {
    const loginData = JSON.parse(localStorage.getItem('loginData'))

    val.temp_password = "abcd1234";
    val.reset_password = "false";
    console.log('val', val)
    val.customerId = loginData.user_id

    // if (Object.keys(val).length > 0) {
    //   const json = await JSON.stringify(val)
    //   // console.log('json', json)
    //   const blob = new Blob([json], { type: 'application/json' })
    //   const url = URL.createObjectURL(blob)
    //   const fileName = val.name + '_' + val.vesselname + '.json'
    //   const a = document.createElement('a')
    //   a.href = url
    //   a.download = fileName
    //   a.click()
    //   URL.revokeObjectURL(url)
    // } else {
    //   return null
    // }
  }






  const onSubmit = async(data) => {
   
    console.log("data",data)

    
    if (data !== "") {
      console.log("data.email", data.email)

      if (users.data.some(user => user.email === data.email)) {
        toast.error(`Email ${data.email} is already exists !`, {
          autoClose: 2000
        })
        return
      }
      if (users.data.some(user => user.username === data.username)) {
        toast.error(`Username ${data.username} is already exists !`, {
          autoClose: 2000
        })
        return
      }

      if (users.data.some(user => user.vesselid == data.vessel[0].id &&user.isactive==true)) {
        toast.error(`Vessel admin is present for same vessel make him Deactivate`, {
          autoClose: 3000
        })
        return
      }




      //check_email_exist(data.email)
    }



    if (data !== "") {
      data.vesselid = data.vessel[0].id;
      data.vesselname = data.vessel[0].name;
      delete data.vessel;
      console.log(data, "data");

      
      if(data.signature.length>0){
        try {
      
          const payload=data.signature[0];
          const response1 = await generateSignUrlApi({ file_name: payload.name });
  
          const image_data = await axios.put(response1.data.data.response.url, payload);
          console.log("image_data", image_data);
  
          if (image_data.status == 200) {
            const requestData = response1.data.data.response.filename;
            data.signature = requestData;
  
          }
          else {
            data.signature = "";
          }
       
      

        
        console.log("data",data)


        createVesselAdminsApi(data).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel admin created successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
  
            setVisible(false);
            reset();
            fetchUsers(1);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );




      } catch (error) {
        console.log("errr",error);

        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      }
      else{
        data.signature="";
        createVesselAdminsApi(data).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel admin created successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
  
            setVisible(false);
            reset();
            fetchUsers(1);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
      



      
    } else {
      errors.showMessages();
    }
  };

  function delVesselAdmin(val) {
    SweetAlert.fire({
      title: "Are you sure to delete? \n" + val.name,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        console.log(val);
        console.log(val.id);
        console.log(val.created);
        console.log(
          "process.env.REACT_APP_APP_ID",
          process.env.REACT_APP_APP_ID
        );
        delVesselAdminsApi({ id: val.id, created: val.created }).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel admin deleted successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            fetchUsers(page);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
    });
  }

  function softDelete(data) {
    const payload = data;
    const params = {
      id: data.id,
    };
    console.log("payload", payload);
    // console.log('props.data', props.data)

    payload.vesselid = payload.vesselid;
    payload.vesselname = payload.vesselname;

    delete payload.vessel;
    console.log("params", params);
    payload.isactive = false;
    SweetAlert.fire({
      title: `Are you sure you want to Deactivate this ${data.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        updateVesselAdminApi(payload, params).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel admin Deactivated successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            fetchUsers(page);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
    });
  }

  return (
    <div className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="card">
        <div
          className="card-header poppins-bold bg-primary-blue"
          style={{ fontSize: "20px" }}
        >
          <ArrowLeftCircle
            style={{ cursor: 'pointer' }}
            className=" me-2"
            onClick={() => {
              navigate(-1, { replace: true })
            }}
          />{' '}
          Vessel Admin List
        </div>
        <div className="card-body">
          <div className="row justify-content-md-between">
            <div className="col-12 col-md-4">
              <label className="poppins-bold">Search</label>
              <input
                className="form-control"
                type="text"
                placeholder="search"
                onChange={(e) => fetchUsers(1, null, e.target.value)}
              />
              {/* <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                multiple={false}
                options={vesselList.data}
                loading={vesselList.loading}
                onChange={(e) => {
                  // fetchUsers(e)
                  console.log('e', e)
                  setSeletedVessel(e)
                }}
                value={selectedVessel}
                //   isLoading={locations.state}
                placeholder="Select Vessel"
                clearButton
              /> */}
            </div>

            <div className="col-12 col-md-3">
              <label className="poppins-bold invisible">Search</label>
              <button
                className="btn btn-primary-blue w-100"
                onClick={() => {
                  setVisible(!visible);
                  setSameAsEmail(false);
                }}
              >
                Create Vessel Admin
              </button>
            </div>
          </div>
          <div className="mt-4">
            {users.loading ? (
              <Spinner color="primary" />
            ) : users.data.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-bordered mt-4">
                  <thead>
                    <tr className="poppins-bold">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Email</th>
                      <th scope="col">Username</th>
                      <th scope="col">Signature</th>
                      <th scope="col">Vessel</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.data.map((val, i) => (
                      <tr key={i}>
                        <td>{i + srNo + 1}</td>
                        <td>{val.name}</td>
                        <td>{val.rank == undefined ? "NA" : val.rank}</td>
                        <td>{val.email}</td>
                        <td>{val.username}</td>
                        <td>
                            {val.signature !== null && val.signature!==""? (
                              <img src={val.signature} height={'60'} width={'60'} alt="Signature" />
                            ) : (
                              'NA'
                            )}
                          </td>
                        <td>{val.vesselname}</td>
                        <td>{val.isactive==true?"Active":"Inactive"}</td>
                        <td>
                          <EditVesselAdmin
                            data={val}
                            fetchVessels={fetchVessels}
                            fetchUsers={fetchUsers}
                            page={page}
                          />


                          <Tooltip
                            placement="bottom"
                            overlay={<span>
                              Delete Vessel Admin</span>}
                          >
                            <Trash2
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => delVesselAdmin(val)}
                            />
                          </Tooltip>


                        {val.isactive&&  <Tooltip
                            placement="bottom"
                            overlay={<span>Download JSON</span>}
                          >
                            <Download
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => download_main_json(val)}
                            />
                          </Tooltip>}




                        {val.isactive&&  <Tooltip
                            placement="bottom"
                            overlay={<span>
                              Deactivate Vessel Admin</span>}
                          >
                            <Archive
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => softDelete(val)}
                            />
                          </Tooltip>}

                          {val.isactive&&<Tooltip
                            placement="bottom"
                            overlay={<span>Create Users</span>}
                          >

                            <PlusCircle
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => navigate("/easyrest/vesselusermaster", { state: { vesselName: val.vesselname, vesselid: val.vesselid } })}

                            />
                          </Tooltip>}




                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="m-0 text-center text-danger">{"No Data Found !"}</p>
            )}

            {users.data.length > 0 ? (
              <div className="mt-4">
                <Pagination
                  className=" cursor_pointer justify-content-end pagination-primary"
                  aria-label="Page navigation example"
                >
                  <Pagination.Item
                    onClick={() => {
                      fetchUsers(page - 1)
                      setPage((prevP) => prevP - 1)
                      setSrNo((prevC) => prevC - 10)
                    }}
                    disabled={page === 1 ? true : false}
                  >
                    Previous
                  </Pagination.Item>
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      fetchUsers(page + 1)
                      setPage((prevP) => prevP + 1)
                      setSrNo((prevC) => prevC + 10)
                    }}
                    disabled={
                      page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    Next
                  </Pagination.Item>
                </Pagination>
                <div>
                  <div className="d-flex justify-content-end">
                    <p
                      className="mb-0"
                      style={{ fontSize: '14px' }}
                    >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p
                      className=""
                      style={{ fontSize: '14px' }}
                    >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal show={visible} onHide={() => setVisible(false)} size="lg">
        <form className="">
          <Modal.Header className="bg-primary-blue " closeButton>
            <Modal.Title>Create Vessel Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder=" Enter Name*"
                    name="name"
                    {...register('name')}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Rank'}</label>
                  <input
                    className={errors.rank ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Enter Rank*"
                    name="rank"
                    {...register('rank')}
                  />
                  {/* <select className="form-control"
                    {...register('rank')}
                  >
                    <option value={""} >{"Select"}</option>
                    {rankdata.map((item)=>{
                      return(
                      
                      <option value={`${item}`}>{item}</option>)
                    })}
                  </select> */}

                  <span className="text-danger">{errors.rank?.message}</span>
                </div>
              </div>



              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Email'}</label>
                  <input
                    className={errors.email ? 'form-control is-invalid' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register('email')}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Username'}</label>
                  <input
                    className={errors.username ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="username"
                    placeholder="Username*"
                    {...register('username')}
                  />
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      checked={sameAsEmail}
                      onChange={(e) => setSameAsEmail(e.target.checked)}
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      style={{ fontSize: '13px', color: 'gray' }}
                      className="form-check-label"
                      htmlFor="exampleCheck1"
                    >
                      Same As Email
                    </label>
                  </div>
                  <span className="text-danger">{errors.username?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Select Vessel'}</label>
                  <Controller
                    name="vessel"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        loading={vesselList.loading}
                        //   isLoading={locations.state}
                        placeholder="Select Vessel"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.vessel ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.vessel?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Password'}</label>
                  <div className="input-group">
                    <input
                      className={errors.password ? 'form-control is-invalid' : 'form-control'}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter Password*"
                      name="password"
                      {...register('password')}
                    />
                    <div className="input-group-append">
                      {showPassword ? (
                        <span className="input-group-text">
                          <EyeOff
                            size={18}
                            className="cursor-pointer my-1"
                            onClick={() => setShowPassword(false)}
                          />
                        </span>
                      ) : (
                        <span className="input-group-text">
                          <Eye
                            size={18}
                            className="cursor-pointer my-1"
                            onClick={() => setShowPassword(true)}
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  <span className="text-danger">{errors.password?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">Signature</label>

                  <input
                    className={errors.signature ? 'form-control is-invalid' : 'form-control'}
                    type={'file'}
                    accept="image/*"
                    {...register('signature')}
                  />

                {/* <span className="text-danger">{errors.signature?.message}</span> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary-blue"
              onClick={handleSubmit(onSubmit)}
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default VesselAdminMaster;
