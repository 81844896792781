import React, { useState, useEffect } from 'react'
import { Edit, Trash2, Archive } from 'react-feather'
import CreateVessel from './CreateVessel'
import { getVesselApi, delVesselApi, updateVesselApi } from '../../Common/Api/index'
import EditVessel from './EditVessel'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import { calMaxPage } from '../../Common/Functions/CommonFunctions'
import Spinner from 'react-bootstrap/Spinner';
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";

import Pagination from 'react-bootstrap/Pagination';

function Vessel() {
  const [vesselList, setVesselList] = useState({ data: [], loading: false })
  const [page, setPage] = useState(1)
  const [srNo, setSrNo] = useState(0)
  const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({ maxPage: '', totalCount: '' })

  const loginData = JSON.parse(localStorage.getItem('loginData'))

  useEffect(() => {
    fetchVessels(page)
  }, [])

  function fetchVessels(p, searchVal) {
    console.log('searchVal', searchVal)
    setVesselList({ ...vesselList, loading: true, data: [] })

    let params = { customer_id: loginData.user_id, limit: 10, offset: p - 1, search: searchVal }

    getVesselApi(params).then(
      (res) => {
        console.log('res', res)
        const data = res.data.data
        console.log('data', data)
        if (data.statusCode == 200) {
          setMaxPageAndTotalCount({
            ...maxPageAndTotalCount,
            maxPage: calMaxPage(res.data.data.response.count.totalData),
            totalCount: res.data.data.response.count.totalData,
          })
          if (res.data.data.response.results.length > 0) {
            setVesselList({ ...vesselList, loading: false, data: res.data.data.response.results })
          } else {
            setVesselList({ ...vesselList, loading: false, data: [] })
          }
        }
      },
      (err) => {
        console.log('err', err)
        toast.error('Something went wrong !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setVesselList({ ...vesselList, loading: false, data: [] })
      },
    )
  }

  function delVessel(val) {
    SweetAlert.fire({
      title: 'Are you sure to delete? \n' + val.name,
      text: 'Once deleted, you will not be able to recover this record !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delVesselApi({ id: val.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Vessel deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchVessels(page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  function softDelete(data) {
    console.log('data', data)
    data.isactive = false
    SweetAlert.fire({
      title: `Are you sure to delete ${data.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api

        updateVesselApi(data, { id: data.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Vessel deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchVessels(page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  return (
    <div className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="card">
        <div className="card-header poppins-bold bg-primary-blue" style={{ fontSize: '20px' }}>
          Vessel List
        </div>
        <div className="card-body ">
          <div className="d-flex justify-content-between">
            <div className='col-12 col-md-3'>
              <label className="poppins-bold">Search</label>
              <input
                className="form-control"
                type="text"
                placeholder="search"
                onChange={(e) => fetchVessels(1, e.target.value)}
              />
            </div>
            <div>
              <label className="poppins-bold invisible">Search</label>
              <CreateVessel fetchVessels={fetchVessels} />
            </div>
          </div>

          <div className=" mt-4">
            {vesselList.loading ? (
              <Spinner animation="border" variant="primary" />

            ) : vesselList.data.length > 0 ? (
              <div className="table-responsive" >
                <table className="table table-striped table-bordered mt-4">
                  <thead>
                    <tr className="poppins-bold">
                      <th scope="col">Sr No</th>
                      <th scope="col">Name</th>
                      <th scope="col">IMO Number</th>
                      <th scope="col">Call Sign</th>
                      <th scope="col">Type</th>
                      <th scope="col">GT</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vesselList.data.map((val, i) => (
                      <tr key={i}>
                        <td>{i + srNo + 1}</td>
                        <td>{val.name}</td>
                        <td>{val.number}</td>
                        <td>{val.uniquenumber !== "" ? val.uniquenumber : 'NA'}</td>
                        <td>{val.vessel_type}</td>
                        <td>{val.gt !== "" ? val.gt : "NA"}</td>
                        <td>{val.status ? "Active" : "Inactive"}</td>
                        <td>
                          <EditVessel data={val} fetchVessels={fetchVessels} page={page} />

                          <Tooltip
                            placement="bottom"
                            overlay={<span>Permanent
                              Delete</span>}
                          >
                            <Trash2
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => delVessel(val)}
                            />
                          </Tooltip>

                          {/* <Tooltip
                            placement="bottom"
                            overlay={<span>Temporary Delete</span>}
                          >
                            <Archive
                              className="cursor-pointer ms-1"
                              size={18}
                              onClick={() => softDelete(val)}
                            />
                          </Tooltip> */}








                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="m-0 text-center text-danger">{'No Data Found !'}</p>
            )}

            {vesselList.data.length > 0 ? (
              // <div className="mt-4">
              //   <nav aria-label="Page navigation example" >
              //     <ul className="pagination cursor_pointer justify-content-end pagination-primary">
              //       <li className={page === 1 ? "page-item disabled" : "page-item"}
              //         onClick={() => {
              //           fetchVessels(page - 1)
              //           setPage((prevP) => prevP - 1)
              //           setSrNo((prevC) => prevC - 10)
              //         }}
              //       ><a className="page-link" href="#">Previous</a></li>
              //       <li className="page-item"><a className="page-link" href="#">{page}</a></li>
              //       <li className={page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
              //         ? "page-item disabled" : "page-item"} onClick={() => {
              //           fetchVessels(page + 1)
              //           setPage((prevP) => prevP + 1)
              //           setSrNo((prevC) => prevC + 10)
              //         }}
              //       ><a className="page-link" href="#">Next</a></li>
              //     </ul>
              //   </nav>

              //   <div>
              //     <div className="d-flex justify-content-end">
              //       <p
              //         className="mb-0"
              //         style={{ fontSize: '14px' }}
              //       >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
              //     </div>
              //     <div className="d-flex justify-content-end">
              //       <p
              //         className=""
              //         style={{ fontSize: '14px' }}
              //       >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
              //     </div>
              //   </div>
              // </div>
              <Pagination
                className=" cursor_pointer justify-content-end pagination-primary"
                aria-label="Page navigation example"
              >
                <Pagination.Item
                  onClick={() => {
                    fetchVessels(page - 1)
                    setPage((prevP) => prevP - 1)
                    setSrNo((prevC) => prevC - 10)
                  }}
                  disabled={page === 1 ? true : false}
                >
                  Previous
                </Pagination.Item>
                <Pagination.Item>{page}</Pagination.Item>
                <Pagination.Item
                  onClick={() => {
                    fetchVessels(page + 1)
                    setPage((prevP) => prevP + 1)
                    setSrNo((prevC) => prevC + 10)
                  }}
                  disabled={
                    page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                      ? true
                      : false
                  }
                >
                  Next
                </Pagination.Item>
              </Pagination>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vessel
