import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Typeahead } from 'react-bootstrap-typeahead'
import { ToastContainer, toast } from 'react-toastify'
import { updateVesselApi, getVesselTypeApi } from '../../Common/Api/index'
import { Edit, Trash2 } from 'react-feather'
import Modal from 'react-bootstrap/Modal';

//import Tooltip from 'rc-tooltip'
//import 'rc-tooltip/assets/bootstrap.css'


import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
const schema = yup
  .object()
  .shape({
    // uniqueNumber: yup.string().required('Vessel unique no is required !'),
    vesselIMONO: yup.string()
    .required('IMO no is required ')
    .test('match', 'Please enter valid IMO number', function (value) {
      return /^[1-9]\d*$/.test(value)}),
    uniquenumber: yup
      .string()
      //.required('Vessel IMO no is required !')
      .typeError('Only numbers allowed'),
    // vesselIMONO: yup.string().required('Vessel IMO No is required !').matches(/^[0-9]*$/, "Enter valid IMO NO !"),
    vesselName: yup
      .string()
      .max(50, '50 characters allowed !')
      .required('Vessel name is required !'),
    vesselType: yup
      .array()
      .min(1, 'Please select vessel type !')
      .nullable()
      .required('Please select vessel type !'),
    GT: yup
    .string()
    .test('match', 'Please enter valid GT', function (value) {
      return /^(?!0)\d*(\.\d+)?$/.test(value); // Matches positive decimal numbers not starting with zero
    }),
    vesselInfo: yup.string().notRequired(),
    status: yup
      .string()
      .nullable()
      .required('Status is required !')
      .matches(/^((?!Select).)*$/, 'Please select valid option'),
  })
  .required()

var FormData = require('form-data')

function EditVessel(props) {
  const [visible, setVisible] = useState(false)
  const [vesselTypeList, setVesselTypeList] = useState({ data: [], loading: false })
  const loginData = JSON.parse(localStorage.getItem('loginData'))

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  useEffect(() => {
    if (visible == true) {
      console.log('props.data', props.data)
      fetchVesselTypes()
    }
  }, [visible])

  function fetchVesselTypes() {
    setVesselTypeList({ ...vesselTypeList, data: [], loading: true })
    getVesselTypeApi().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          setVesselTypeList({
            ...vesselTypeList,
            data: res.data.data.response.results,
            loading: false,
          })
        } else {
          setVesselTypeList({ ...vesselTypeList, data: [], loading: false })
        }
      },
      (err) => {
        console.log('err', err)
      },
    )
  }

  const onSubmit = (data) => {
    if (data !== '') {
      console.log('data', data)

      const reqPayload = {
        number: data.vesselIMONO,
        uniquenumber: data.uniquenumber,
        type_id: data.vesselType[0].id,
        vessel_type:data.vesselType[0].name,
        name: data.vesselName,
        gt: data.GT,
        status: data.status == 'true' ? true : false,
        customerId: loginData.user_id,
      }
      console.log('reqPayload', reqPayload)

      updateVesselApi(reqPayload, { id: props.data.id }).then(
        (res) => {
          console.log('res', res)
          toast.success('Vessel updated successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          props.fetchVessels(props.page)
          setVisible(!visible)
          reset()
        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
      )
    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

           <Tooltip
                            placement="bottom"
                            overlay={<span>Edit Vessel</span>}
                          >
              
              <Edit size={18} className="cursor-pointer " onClick={() => setVisible(!visible)} />
      
      </Tooltip>

      <Modal show={visible} onHide={() => setVisible(false)} size='lg'>
        <Modal.Header className='bg-primary-blue' closeButton>
          <Modal.Title>Update Vessel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="row">


              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'IMO No'}</label>
                  <input
                    className={
                      errors.vesselIMONO ? 'form-control is-invalid f-16' : ' f-16 form-control'
                    }
                    type="text"
                    placeholder="Enter IMO No*"
                    name="vesselIMONO"
                    {...register('vesselIMONO')}
                    defaultValue={props.data?.number}
                  />

                  <span className="text-danger">{errors.vesselIMONO?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Call Sign'}</label>
                  <input
                    className={
                      errors.uniquenumber ? 'form-control is-invalid f-16' : ' f-16 form-control'
                    }
                    type="text"
                    placeholder="Enter Call Sign"
                    name="uniquenumber"
                    {...register('uniquenumber')}
                    defaultValue={props.data?.uniquenumber}
                  />

                  <span className="text-danger">{errors.uniquenumber?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Vessel Name'}</label>
                  <input
                    className={
                      errors.vesselName ? 'form-control is-invalid f-16' : ' f-16 form-control'
                    }
                    type="text"
                    placeholder="Enter vessel name*"
                    name="vesselName"
                    {...register('vesselName')}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.vesselName?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Vessel Type'}</label>

                  <Controller
                    name="vesselType"
                    control={control}
                    defaultValue={[{ name: props.data?.vessel_type, type_id: props.data?.type_id }]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        defaultSelected={[
                          { name: props.data?.vessel_type, type_id: props.data?.type_id },
                        ]}
                        options={vesselTypeList.data}
                        isLoading={vesselTypeList.loading}
                        placeholder="Select Vessel Type*"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.vesselType ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.vesselType?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'GT'}</label>
                  <input
                    className={errors.GT ? 'form-control is-invalid f-16' : ' f-16 form-control'}
                    type="text"
                    placeholder="Enter GT*"
                    name="GT"
                    {...register('GT')}
                    defaultValue={props.data?.gt}
                  />

                  <span className="text-danger">{errors.GT?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Status'}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={props.data?.status}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.status}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? 'custom-select form-control is-invalid f-16'
                            : ' f-16 custom-select form-control'
                        }
                      >
                        <option>{'Select*'}</option>
                        <option value={true}>{'Active'}</option>
                        <option value={false}>{'Inactive'}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary-blue" onClick={handleSubmit(onSubmit)}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  )
}

export default EditVessel
