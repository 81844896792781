import React, { useEffect } from "react";
import Vessel from '../Project Components/VesselMaster/Vessel'
import UploadRestHours from '../Project Components/UploadRestHours/UploadRestHours'
import Report from '../Project Components/Report/Report'
import VesselAdminMaster from "../Project Components/UserMaster/VesselAdminMaster";
import ResetPassword from "../Project Components/Authentication/ResetPassword"
import ConsolidatedReport from "../Project Components/Report/ConsolidatedReport"
import VesselUserMaster from "../Project Components/UserMaster/VesselUserMaster";
const Home = React.lazy(() => import("../Project Components/Home"));

const routes = [
    { path: "/dashboard", name: "Dashboard", element: Home },
    { path: "/easyrest/vessels", name: "Vessel", element: Vessel },
    { path: "/easyrest/resthours", name: "UploadRestHours", element: UploadRestHours },
    { path: "/easyrest/report", name: "Report", element: Report },
    { path: '/easyrest/vesseladminmaster', name: 'VesselAdminMaster', element: VesselAdminMaster },
    { path: '/easyrest/vesselusermaster', name: 'VesselUserMaster', element: VesselUserMaster },
    { path: '/easyrest/resetpassword', name: 'ResetPass', element: ResetPassword },
    { path: '/easyrest/consolidatedreport', name: 'creport', element: ConsolidatedReport },
];

export default routes;
