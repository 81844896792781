export const login = '/login-user'
export const addVessel = '/add-vessel'
export const getVessel = '/all-vessel'
export const delVessel = '/delete-vessel'
export const updateVessel = '/update-vessel'
export const vesselTypes = '/all-vessel-types'
export const allCountries = '/all-country'
export const addRestHours = 'add-rest-hours'
export const uploadRestHours='/api/upload-rest-hours'
export const getRestHours = 'all-rest-hours'
export const getCustomer = 'get-customer'
export const delCustomer = 'delete-customer'
export const updateCustomer = 'update-customer'
export const generateSignUrl = 'generate-signature-file-url'
export const getVesselAdmins = 'get-vessel-admins'
export const createVesselAdmins = 'add-vessel-admin'
export const deleteVesselAdmin = 'delete-vessel-admin'
export const updateVesselAdmin='update-vessel-admin'
export const forgetPassword = '/forgot-password'
export const resetPassword = '/reset-password'
export const getVesselUsers = "/get-vessel-users"
export const createVesselUser="/add-vessel-user"
export const updateVesselUser="/update-vessel-user"