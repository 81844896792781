import React from 'react'
import { Party_address_master } from "../Project Components/Party_address_master";
import Party_contact_master from "../Project Components/Party_contact_master";
import Party_type_master from "../Project Components/Party_type_master";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faPlus,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";


function Home() {
  return (
    <div>
      <div className='card' id='party_master'>
        <div className='card-body' style={{ padding: '0px 0px 30px 0px' }}>
          <button
            className='btn btn-pink px-4'
            style={{ position: 'absolute', top: '14px', right: '20px' }}>
            <FontAwesomeIcon style={{ color: '#fff' }} icon={faPlus} size="sm" /> Add New</button>
          <Tabs
            variant="pills"
            defaultActiveKey="company"
            id="uncontrolled-tab-example"
            className="mb-3 "
            style={{ padding: '0px 30px 0px 30px' }}
          >
            <Tab eventKey="company" title="Company Master">
              <div style={{ padding: '0px 30px 0px 30px' }}>
                <Party_type_master />
              </div>
            </Tab>
            <Tab eventKey="contact_master" title="Contact Master">
              <div style={{ padding: '0px 30px 0px 30px' }}>
                <Party_contact_master />
              </div>
            </Tab>
            <Tab eventKey="address_master" title="Address Master">
              <div style={{ padding: '0px 30px 0px 30px' }}>
                <Party_address_master />
              </div>
            </Tab>
            <Tab eventKey="bank_master" title="Bank Master">
              <div style={{ padding: '0px 30px 0px 30px' }}>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Home