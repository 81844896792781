import React, { useState, useEffect } from 'react'
import { postRestHoursApi, getRestHoursApi, getVesselApi, getVesselUsersApi, getVesselAdminsApi } from '../../Common/Api/index'
import { toast, ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { Typeahead } from 'react-bootstrap-typeahead'
import months from '../../Common/sampleJson/months'
// import '../../common/css/style.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
import { ArrowLeftCircle } from 'react-feather'
import Poppins from '../../../src/assets/fonts/Poppins/Poppins-Regular.ttf'
import PoppinsBold from '../../../src/assets/fonts/Poppins/Poppins-SemiBold.ttf'
function ConsolidatedReport() {
  let navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState('')
  const [loader, setLoader] = useState(false)
  const loginData = JSON.parse(localStorage.getItem('loginData'))
  const [vesselList, setVesselList] = useState({ data: [], loading: false })
  const [selectVessel, setSelectVessel] = useState("");
  const [pdfUrl, setPdfData] = useState(null);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [notfound, setNotfound] = useState(false);
  const url = `${process.env.REACT_APP_CONSOLIDATED_PDF}`;
  const [down,setDown]=useState(false)
  useEffect(() => {
    fetchVessels()
  }, [])

  function fetchVessels() {

    setVesselList({ ...vesselList, loading: true, data: [] })

    let params = { customer_id: loginData.user_id, }

    getVesselApi(params).then(
      (res) => {
        console.log('res', res)
        const data = res.data.data
        console.log('data', data)
        if (data.statusCode == 200) {

          if (res.data.data.response.results.length > 0) {
            setVesselList({ ...vesselList, loading: false, data: res.data.data.response.results })
          } else {
            setVesselList({ ...vesselList, loading: false, data: [] })
          }
        }
      },
      (err) => {
        console.log('err', err)

        setVesselList({ ...vesselList, loading: false, data: [] })
      },
    )
  }



  async function searchRestHourOfUser() {
    // console.log('months', months)
    console.log('selectedDate', selectedDate)
    console.log('selectedDate.length', selectedDate.length)
    setLoader(true)


    if (selectVessel == "") {
      toast.error('Please select vessel !', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      setLoader(false)
      return

    }

    if (selectedDate.length < 1) {
      toast.error('Month is required !', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      setLoader(false)


    } else {

      console.log('selectedDate', selectedDate)

      const currentMonth = months[selectedDate.getMonth()]
      const currentYear = selectedDate.getFullYear()

      console.log('currentMonth', currentMonth)
      console.log('currentYear', currentYear);

      let token = localStorage.getItem('token')
      const base64 = btoa(token);

      let payload = {
        auth: base64,
        customerId: loginData.user_id,
        month: currentMonth,
        year: currentYear,
        vesselid: selectVessel,
        logo:loginData.logo
      }


      axios.post(url, payload, { responseType: "arraybuffer" }).then(
        (res) => {
          console.log("res", res);

          if (res.status === 200 ) {

            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            // const pdfData = new Blob([res.data], { type: 'application/pdf' });
            const pdfData = new Blob([res.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(pdfData);
            setPdfData(pdfUrl);
            setLoader(false);
            setDown(true)

          }
          
        },
        (err) => {
          setNotfound(true)
          setLoader(false);
        }
      );



    }
  }

  const handleclick = () => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = `Rest_hour.pdf`;
    anchor.click();
  };




  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="creport">
        <div className="row">
          <div className="col-12">
            <div className="card my-4">
              <div
                className="card-header poppins-bold bg-primary-blue"
                style={{ fontSize: '20px' }}
              >
                <ArrowLeftCircle
                  style={{ cursor: 'pointer' }}
                  className=" me-2"
                  onClick={() => {
                    navigate(-1, { replace: true })
                  }}
                />{' '}
                Reports
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <label className="poppins-bold">Select Month And Year</label>
                        <DatePicker
                          className="form-control"
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          placeholderText="Select Month & Year"
                        />
                      </div>

                      <div className="col-12 col-md-3">
                        <label className="poppins-bold">Please Select Vessel</label>

                        <select className='form-control' onChange={(e) => {
                          console.log("val", e.target.value)
                          setSelectVessel(e.target.value);
                        }}>
                          <option>Select Vessel</option>
                          {vesselList.data.map((item) => {
                            return <option value={item.id}>{item.name}</option>
                          })}

                        </select>

                      </div>

                      <div className="col-12 col-md-4">
                        <label className="poppins-bold invisible d-block">Submit</label>
                        <button
                          className="btn btn-primary-blue"
                          onClick={() => searchRestHourOfUser()}
                        >
                          Submit
                        </button>
                      </div>

                      <div className="col-12 mt-4">
                        {notfound ?
                          <div>

                            <p className="text-center text-danger">No data found !</p>
                          </div>
                          :
                          <>
                            {loader ? (
                              <Spinner color="primary" component="span" aria-hidden="true" size='sm' />
                            ) :
                              <>

                                <iframe src={pdfUrl} width="100%" height="700px"></iframe>
                                <div className="d-flex justify-content-end">
                                  {
                                    down && <button
                                      className="btn btn-primary-blue mt-4 "
                                      onClick={handleclick}
                                    >
                                      {"Download Report"}
                                    </button>
                                  }
                                </div>
                              </>
                            }</>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ConsolidatedReport
