import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Eye, EyeOff } from 'react-feather'

import { Typeahead } from 'react-bootstrap-typeahead'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import { updateVesselAdminApi, getVesselApi, getVesselAdminsApi, generateSignUrlApi } from '../../Common/Api/index'
import { downloadAsJSON } from '../../Common/Functions/CommonFunctions'
import { Edit, Trash2 } from 'react-feather'
//import Tooltip from 'rc-tooltip'
//import 'rc-tooltip/assets/bootstrap.css'

import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import axios from 'axios'
const schema = yup
  .object()
  .shape({
    name: yup.string().max(50, '50 characters allowed !').required('Name is required !'),
    rank: yup.string().max(50, '50 characters allowed !').required('Rank is required !'),
    email: yup.string().required('Email is required !').email('Please enter valid email !'),
    username: yup.string().required('Username is required !'),
    vessel: yup.array().min(1, 'Please select vessel !').required('Please select vessel !'),
    //adminEmail: yup.string().required('Email is required !').email('Please enter valid email !'),
    // password: yup
    //   .string()
    //   .required('Password is required !')
    //   .min(6, 'Minimum 6 characters required !')
    //   .max(16, 'Maximum 16 characters allowed !')
    //   .matches(/^((?!\s).)*$/, 'Space not allowed'),
    status: yup
      .string()
      .nullable()
      .required('Status is required !')
      .matches(/^((?!Select).)*$/, 'Please select valid option'),
  

  })
  .required()

var FormData = require('form-data')

function EditVesselAdmin(props) {

  const [visible, setVisible] = useState(false)
  const loginData = JSON.parse(localStorage.getItem('loginData'))
  const [showPassword, setShowPassword] = useState(false)
  const [sameAsEmail, setSameAsEmail] = useState(false)
  const [vesselList, setVesselList] = useState({ data: [], loading: false })
  const [users, setUsers] = useState({ data: [], loading: false });

  // console.log(props)
  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState

  useEffect(() => {
    console.log('props.data', props.data)
    if (visible == true) {
      reset()
      fetchVessels()
      fetchUsers()
      setValue('vessel', [{ id: props.data.vesselid, name: props.data.vesselname }])
    }
  }, [visible])

  const fetchUsers = () => {
    setUsers({ ...users, data: [], loading: true });

    const params = {};

    console.log("params", params)

    getVesselAdminsApi(params).then(
      (res) => {
        console.log("ressd", res);
        if (res.data.data.statusCode == 200) {

          if (res.data.data.response.results.length > 0) {

            let data_use = res.data.data.response.results.filter((item) => item.id !== props.data.id)
            setUsers({
              ...users,
              loading: false,
              data: data_use,
            });
          } else {
            setUsers({ ...users, loading: false, data: [] });
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };



  function fetchVessels() {
    setVesselList({ ...vesselList, data: [], loading: true })
    getVesselApi({ customer_id: loginData.user_id }).then(
      (res) => {
        console.log('res', res)
        let data = res.data.data

        let ves_data = data.response.results.filter((item) => item.status == true);
        data.response.results = ves_data
        console.log('data', data)
        if (data.statusCode == 200) {
          setVesselList({ ...vesselList, data: data.response.results, loading: false })
        }
      },
      (err) => {
        console.log('err', err)
      },
    )
  }

  const onSubmit = async (data) => {
    console.log("data",data)
    console.log('props.data', props.data);
//console.log("sign",data.signature.length)
   
    if (data !== '') {
      console.log("data", data.status)
      console.log("props.data.isactive", props.data.isactive)
      if (data.status !== `${props.data.isactive}`) {


        if (users.data.some(user => user.vesselid == data.vessel[0].id && user.isactive == true)) {
          toast.error(`Vessel admin is present for same vessel make him Deactivate`, {
            autoClose: 3000
          })
          return
        }
      }
      if (users.data.some(user => user.email === data.email)) {
        toast.error(`Email ${data.email} is already exists !`, {
          autoClose: 2000
        })
        return
      }
      if (users.data.some(user => user.username === data.username)) {
        toast.error(`Username ${data.username} is already exists !`, {
          autoClose: 2000
        })
        return
      }


      const payload = data
      const params = {
        id: props.data.id,
      }
      console.log('payload', payload)
      console.log('props.data', props.data)

      payload.vesselid = payload.vessel[0].id
      payload.vesselname = payload.vessel[0].name
      payload.isactive = data.status == "false" ? false : true
      delete payload.status
      delete payload.vessel
      console.log('params', params);
      console.log('payload', payload);

    if(data.signature.length==0){

       data.signature=props.data.signature;
      updateVesselAdminApi(payload, params).then(
        (res) => {
          console.log('res', res)
          toast.success('Vessel admin updated successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          setVisible(!visible)
          props.fetchUsers(props.page)
        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
      )
    }
    else{
      try {
        const sign_payload=data.signature[0];
        const response1 = await generateSignUrlApi({ file_name: sign_payload.name });

        const image_data = await axios.put(response1.data.data.response.url, sign_payload);
        console.log("image_data", image_data);

        if (image_data.status == 200) {
          const requestData = response1.data.data.response.filename;
          payload.signature = requestData;

        }
        else {
          payload.signature = "";
        }

        
         console.log("payload",payload)


        updateVesselAdminApi(payload, params).then(
          (res) => {
            console.log('res', res)
            toast.success('Vessel admin updated successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            setVisible(!visible)
            props.fetchUsers(props.page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )




      } catch (error) {
        console.log("errr",error);

        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }



    }



    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />


      <Tooltip
        placement="bottom"
        overlay={<span>Edit Vessel Admin</span>}
      >

        <Edit size={18} className="cursor-pointer " onClick={() => setVisible(!visible)} />

      </Tooltip>

      <Modal show={visible} onHide={() => setVisible(false)} size='lg'>
        <form className="">
          <Modal.Header className="bg-primary-blue " closeButton>
            <Modal.Title>Update Vessel Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Enter Name*"
                    name="name"
                    {...register('name')}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Rank'}</label>
                  <input
                    className={errors.rank ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Enter Rank*"
                    name="rank"
                    {...register('rank')}
                    defaultValue={props.data?.rank}
                  />
                  {/* <select className="form-control"
                    {...register('rank')}
                    defaultValue={props.data.rank}
                  >
                    {rankdata.map((item) => {
                      return (<option value={`${item}`}>{item}</option>)
                    })}

                  </select> */}

                  <span className="text-danger">{errors.rank?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Email'}</label>
                  <input
                    className={errors.email ? 'form-control is-invalid' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register('email')}
                    defaultValue={props.data?.email}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Username'}</label>
                  <input
                    className={errors.username ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="username"
                    placeholder="Username*"
                    {...register('username')}
                    defaultValue={props.data?.username}
                  />

                  <span className="text-danger">{errors.username?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Select Vessel'}</label>
                  <Controller
                    name="vessel"
                    control={control}
                    // defaultValue={[{ name: props.data?.vesselname }]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        loading={vesselList.loading}
                        placeholder="Select Vessel"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.vessel ? true : false}
                        defaultSelected={[
                          { id: props.data.vesselid, name: props.data?.vesselname },
                        ]}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.vessel?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Status'}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={props.data?.isactive}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.isactive}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? 'custom-select form-control is-invalid f-16'
                            : ' f-16 custom-select form-control'
                        }
                      >
                        <option>{'Select*'}</option>
                        <option value={true}>{'Active'}</option>
                        <option value={false}>{'Inactive'}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>


              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">Signature</label>

                  <input
                    className={errors.signature ? 'form-control is-invalid' : 'form-control'}
                    type={'file'}
                    accept="image/*"
                    {...register('signature')}
                  />

                  <span className="text-danger">{errors.signature?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary-blue" onClick={handleSubmit(onSubmit)}>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  )
}

export default EditVesselAdmin
