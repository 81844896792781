import React, { useState, useEffect } from 'react'
import { postRestHoursApi, getRestHoursApi, getVesselApi } from '../../Common/Api/index'
import { toast, ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { Typeahead } from 'react-bootstrap-typeahead'
import months from '../../Common/sampleJson/months'
// import { CFormCheck } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import { getCustomerApi, generateSignUrlApi, updateCustApi } from '../../Common/Api/index'
import axios from 'axios'
import { Controller } from 'react-hook-form'
import { Spinner } from 'react-bootstrap'
// import { CButton, CSpinner } from '@coreui/react'



function UploadRestHours() {
  let navigate = useNavigate()
  const url = process.env.REACT_APP_UPLOAD;
  // console.log("url",url)
  const [data, setData] = useState([])

  const [selectedDate, setSelectedDate] = useState('')
  const [selectedUser, setSelectedUser] = useState([])
  const [daysHour, setDaysHour] = useState([])
  const [loader, setLoader] = useState(false)
  const [hours, setHours] = useState([])
  const [selectedUserData, setSelectedUserData] = useState({})
  const [submitClick, setSubmitClick] = useState(false)
  const loginData = JSON.parse(localStorage.getItem('loginData'))
  const [vesselList, setVesselList] = useState({ data: [], loading: false })
  const [selectVessel, setSelectVessel] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  let smallWeekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const [loading, setLoading] = useState(false);
  let dataArray = []
  const addzero = (n) => {
    return n > 9 ? '' + n : '0' + n
  }
  useEffect(() => {

    fetchVessels()
  }, [])

  function fetchVessels() {

    setVesselList({ ...vesselList, loading: true, data: [] })

    let params = { customer_id: loginData.user_id, }

    getVesselApi(params).then(
      (res) => {
        console.log('res', res)
        const data = res.data.data
        console.log('data', data)
        if (data.statusCode == 200) {

          if (res.data.data.response.results.length > 0) {
            setVesselList({ ...vesselList, loading: false, data: res.data.data.response.results })
          } else {
            setVesselList({ ...vesselList, loading: false, data: [] })
          }
        }
      },
      (err) => {
        console.log('err', err)

        setVesselList({ ...vesselList, loading: false, data: [] })
      },
    )
  }


  function onFileChange(e) {


    setSelectedFile(e.target.files[0]);
    console.log('e', e)
    const sal = e.target.files[0]
    const reader = new FileReader()
    reader.readAsText(sal, 'UTF-8')
    reader.onload = (e) => {
      const fileContent = JSON.parse(e.target.result)
      console.log('fileContent', fileContent)
      if (fileContent) {
        if (Object.keys(fileContent).length > 0) {
          setData(fileContent)
        } else {
          toast.error('File is empty !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      } else {
        toast.error('JSON file error !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    }
  }




  async function uploadData() {
    setLoading(true)
    console.log('data', data)

    if (data.applicationtype == "Secondary") {
      toast.error("Please upload JSON file from primary desktop application !", {
        autoClose: 2000,
      })
      setLoading(false)
      return

    };



    if (data.length == 0) {
      toast.error('Please select json file !', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      setLoading(false)
      return
    }


    // getRestHoursApi({
    //   customerId: loginData.user_id,
    //   vesselid: data.vesselid
    // }).then(
    //   async (res) => {
    //     console.log('res', res)
    //     if (res.data.data.response.results.length > 0) {
    //       console.log("res_data_data", res.data.data.response.results)
    //       let arr = res.data.data.response.results;

    //       console.log("arr", arr)
    //       let combined_array = mergeArrays(arr, data.records);
    //       console.log("combined", combined_array)
    //       // converting vessel admin signature to file object and creating url by using api
    //       if (data.vesseladminsignature !== null && data.vesseladminsignature !== undefined) {
    //         const binaryString = atob(data.vesseladminsignature)
    //         const byteArray = new Uint8Array(binaryString.length)
    //         for (let i = 0; i < binaryString.length; i++) {
    //           byteArray[i] = binaryString.charCodeAt(i)
    //         }
    //         const blob = new Blob([byteArray], { type: 'image/png' })
    //         const fileName = new Date().getTime() + 'sign.png'
    //         const file = new File([blob], fileName, { type: 'image/png' })
    //         console.log('file', file)
    //         console.log('fileName', fileName)

    //         await generateSignUrlApi({ file_name: fileName }).then(
    //           async (res) => {
    //             console.log('res', res)

    //             await axios.put(res.data.data.response.url, file).then(
    //               (sres) => {
    //                 console.log('sres', sres)
    //                 data.vesseladminsignature = res.data.data.response.filename
    //               },
    //               (serr) => {
    //                 console.log('serr', serr)
    //                 toast.error('Failed saving vesel admin signature!', {
    //                   position: 'top-right',
    //                   autoClose: 2000,
    //                   hideProgressBar: false,
    //                   closeOnClick: true,
    //                   pauseOnHover: true,
    //                   draggable: true,
    //                   progress: undefined,
    //                   theme: 'light',
    //                 })
    //               },
    //             )
    //           },
    //           (err) => {
    //             console.log('err', err)
    //             toast.error('Failed saving vessel admin signature!', {
    //               position: 'top-right',
    //               autoClose: 2000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: 'light',
    //             })
    //           },
    //         )
    //       } else {
    //         toast.error('Vessel admin signature not available!', {
    //           position: 'top-right',
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: 'light',
    //         })
    //       }

    //       // converting every signature in records to file object then giving to api to store in bucket and store url in our db

    //       // for (let i = 0; i < combined_array; i++) {

    //       //   if (combined_array[i].signature !== undefined && combined_array[i].signature !== null) {
    //       //     const binaryString = atob(combined_array.signature)
    //       //     const byteArray = new Uint8Array(binaryString.length)
    //       //     for (let i = 0; i < binaryString.length; i++) {
    //       //       byteArray[i] = binaryString.charCodeAt(i)
    //       //     }
    //       //     const blob = new Blob([byteArray], { type: 'image/png' })
    //       //     const fileName = new Date().getTime() + 'sign.png'
    //       //     const file = new File([blob], fileName, { type: 'image/png' })
    //       //     console.log('file', file)
    //       //     console.log('fileName', fileName)

    //       //    await generateSignUrlApi({ file_name: fileName }).then(
    //       //       async (res) => {
    //       //         console.log('res', res)

    //       //         await axios.put(res.data.data.response.url, file).then(
    //       //           (sres) => {
    //       //             console.log('sres', sres)
    //       //             combined_array.signature = res.data.data.response.filename
    //       //           },
    //       //           (serr) => {
    //       //             console.log('serr', serr)
    //       //             toast.error('Failed saving signature!', {
    //       //               position: 'top-right',
    //       //               autoClose: 2000,
    //       //               hideProgressBar: false,
    //       //               closeOnClick: true,
    //       //               pauseOnHover: true,
    //       //               draggable: true,
    //       //               progress: undefined,
    //       //               theme: 'light',
    //       //             })
    //       //           },
    //       //         )
    //       //       },
    //       //       (err) => {
    //       //         console.log('err', err)
    //       //         toast.error('Failed saving signature!', {
    //       //           position: 'top-right',
    //       //           autoClose: 2000,
    //       //           hideProgressBar: false,
    //       //           closeOnClick: true,
    //       //           pauseOnHover: true,
    //       //           draggable: true,
    //       //           progress: undefined,
    //       //           theme: 'light',
    //       //         })
    //       //       },
    //       //     )

    //       //   } else {
    //       //     toast.error('Signature not present !', {
    //       //       position: 'top-right',
    //       //       autoClose: 2000,
    //       //       hideProgressBar: false,
    //       //       closeOnClick: true,
    //       //       pauseOnHover: true,
    //       //       draggable: true,
    //       //       progress: undefined,
    //       //       theme: 'light',
    //       //     })
    //       //   }
    //       // }
    //       const apiCalls = [];
    //       async function processArray() {


    //         for (let i = 0; i < combined_array.length; i++) {
    //           const item = combined_array[i];

    //             if(item.signature.startsWith("https://")){
    //               apiCalls.push(item);

    //             }
    //             else if (item.signature !== undefined && item.signature !== null) {

    //             const binaryString = atob(item.signature)
    //             const byteArray = new Uint8Array(binaryString.length)
    //             for (let i = 0; i < binaryString.length; i++) {
    //               byteArray[i] = binaryString.charCodeAt(i)
    //             }
    //             const blob = new Blob([byteArray], { type: 'image/png' })
    //             const fileName = new Date().getTime() + 'sign.png'
    //             const file = new File([blob], fileName, { type: 'image/png' })
    //             console.log('file', file)
    //             console.log('fileName', fileName)
    //             try {

    //               const response1 = await generateSignUrlApi({ file_name: fileName }); 


    //               const requestData =response1.data.data.response.filename ;

    //               item.signature = requestData;

    //               // Push the processed item to the apiCalls array
    //               apiCalls.push(item);
    //             } catch (error) {
    //               console.error('Error saving signature', error);
    //               // Handle the error appropriately
    //             }
    //           } else {
    //             console.log('Signature not present !');
    //           }
    //         }
    //       }
    //       processArray()

    //       let reqPayload = {
    //         vesselid: data.vesselid,
    //         clientid: data.clientid,
    //         vesselname: data.vesselname,
    //         vesseladminname: data.vesseladminname,
    //         vesseladminusername: data.vesseladminusername,
    //         vesseladminsignature: data.vesseladminsignature,
    //         month: data.month,
    //         year: data.year,
    //         records:apiCalls
    //         ,
    //       }
    //       console.log('reqPayload', reqPayload)
    //       postRestHoursApi(reqPayload).then(
    //         (res) => {
    //           console.log('res', res)
    //           toast.success('Rest Hours Upload Successfully !', {
    //             position: 'top-right',
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: 'light',
    //           })
    //           setData([])

    //           const ele = document.getElementById('fileUpload')
    //           ele.value = ''
    //           setSubmitClick(false)
    //         },
    //         (err) => {
    //           console.log('err', err)
    //           toast.error('Something went wrong !', {
    //             position: 'top-right',
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: 'light',
    //           })
    //           setSubmitClick(false)
    //         },
    //       )
    //     } else {

    //       // converting vessel admin signature to file object and creating url by using api
    //       if (data.vesseladminsignature !== null && data.vesseladminsignature !== undefined) {
    //         const binaryString = atob(data.vesseladminsignature)
    //         const byteArray = new Uint8Array(binaryString.length)
    //         for (let i = 0; i < binaryString.length; i++) {
    //           byteArray[i] = binaryString.charCodeAt(i)
    //         }
    //         const blob = new Blob([byteArray], { type: 'image/png' })
    //         const fileName = new Date().getTime() + 'sign.png'
    //         const file = new File([blob], fileName, { type: 'image/png' })
    //         console.log('file', file)
    //         console.log('fileName', fileName)

    //         await generateSignUrlApi({ file_name: fileName }).then(
    //           async (res) => {
    //             console.log('res', res)

    //             await axios.put(res.data.data.response.url, file).then(
    //               (sres) => {
    //                 console.log('sres', sres)
    //                 data.vesseladminsignature = res.data.data.response.filename
    //               },
    //               (serr) => {
    //                 console.log('serr', serr)
    //                 toast.error('Failed saving vesel admin signature!', {
    //                   position: 'top-right',
    //                   autoClose: 2000,
    //                   hideProgressBar: false,
    //                   closeOnClick: true,
    //                   pauseOnHover: true,
    //                   draggable: true,
    //                   progress: undefined,
    //                   theme: 'light',
    //                 })
    //               },
    //             )
    //           },
    //           (err) => {
    //             console.log('err', err)
    //             toast.error('Failed saving vessel admin signature!', {
    //               position: 'top-right',
    //               autoClose: 2000,
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //               theme: 'light',
    //             })
    //           },
    //         )
    //       } else {
    //         toast.error('Vessel admin signature not available!', {
    //           position: 'top-right',
    //           autoClose: 2000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: 'light',
    //         })
    //       }

    //       // converting every signature in records to file object then giving to api to store in bucket and store url in our db

    //       // for (let i = 0; i < data.records.length; i++) {
    //       //   if (data.records[i].signature !== undefined && data.records[i].signature !== null) {
    //       //     const binaryString = atob(data.records[i].signature)
    //       //     const byteArray = new Uint8Array(binaryString.length)
    //       //     for (let i = 0; i < binaryString.length; i++) {
    //       //       byteArray[i] = binaryString.charCodeAt(i)
    //       //     }
    //       //     const blob = new Blob([byteArray], { type: 'image/png' })
    //       //     const fileName = new Date().getTime() + 'sign.png'
    //       //     const file = new File([blob], fileName, { type: 'image/png' })
    //       //     console.log('file', file)
    //       //     console.log('fileName', fileName)

    //       //     await generateSignUrlApi({ file_name: fileName }).then(
    //       //       async (res) => {
    //       //         console.log('res', res)

    //       //         await axios.put(res.data.data.response.url, file).then(
    //       //           (sres) => {
    //       //             console.log('sres', sres)
    //       //             data.records[i].signature = res.data.data.response.filename
    //       //           },
    //       //           (serr) => {
    //       //             console.log('serr', serr)
    //       //             toast.error('Failed saving signature!', {
    //       //               position: 'top-right',
    //       //               autoClose: 2000,
    //       //               hideProgressBar: false,
    //       //               closeOnClick: true,
    //       //               pauseOnHover: true,
    //       //               draggable: true,
    //       //               progress: undefined,
    //       //               theme: 'light',
    //       //             })
    //       //           },
    //       //         )
    //       //       },
    //       //       (err) => {
    //       //         console.log('err', err)
    //       //         toast.error('Failed saving signature!', {
    //       //           position: 'top-right',
    //       //           autoClose: 2000,
    //       //           hideProgressBar: false,
    //       //           closeOnClick: true,
    //       //           pauseOnHover: true,
    //       //           draggable: true,
    //       //           progress: undefined,
    //       //           theme: 'light',
    //       //         })
    //       //       },
    //       //     )
    //       //   } else {
    //       //     toast.error('Signature not present !', {
    //       //       position: 'top-right',
    //       //       autoClose: 2000,
    //       //       hideProgressBar: false,
    //       //       closeOnClick: true,
    //       //       pauseOnHover: true,
    //       //       draggable: true,
    //       //       progress: undefined,
    //       //       theme: 'light',
    //       //     })
    //       //   }
    //       // }

    //       const aCalls = [];

    //       async function processArr() {
    //       // console.log("data_records",data.records)
    //         for (let i = 0; i < data.records.length; i++) {
    //           let item = data.records[i];

    //           //console.log("datarecords",data.records[i])
    //          // console.log(item.signature)
    //          // console.log(item.signature.startsWith("https"))
    //             if(item.signature.startsWith("https")){
    //               aCalls.push(item);
    //               console.log("item",i,item)

    //             }
    //             else if (item.signature !== undefined && item.signature !== null) {

    //             const binaryString = atob(item.signature)
    //             const byteArray = new Uint8Array(binaryString.length)
    //             for (let i = 0; i < binaryString.length; i++) {
    //               byteArray[i] = binaryString.charCodeAt(i)
    //             }
    //             const blob = new Blob([byteArray], { type: 'image/png' })
    //             const fileName = new Date().getTime() + 'sign.png'
    //             const file = new File([blob], fileName, { type: 'image/png' })
    //             console.log('file', file)
    //             console.log('fileName', fileName)
    //             try {

    //               const response1 = await generateSignUrlApi({ file_name: fileName }); 


    //               const requestData =response1.data.data.response.filename ;

    //               item.signature = requestData;

    //               console.log("item",requestData)

    //               aCalls.push(item);
    //             } catch (error) {
    //               console.error('Error saving signature', error);

    //             }
    //           } else {
    //             console.log('Signature not present !');
    //           }
    //         }
    //       }
    //       processArr()

    //         console.log("acall",aCalls)
    //       const reqPayload = {
    //         vesselid: data.vesselid,
    //         clientid: data.clientid,
    //         vesselname: data.vesselname,
    //         vesseladminname: data.vesseladminname,
    //         vesseladminusername: data.vesseladminusername,
    //         vesseladminsignature: data.vesseladminsignature,
    //         month: data.month,
    //         year: data.year,
    //         records:data.records
    //       }
    //       console.log('reqPayload', reqPayload)
    //       postRestHoursApi(reqPayload).then(
    //         (res) => {
    //           console.log('res', res)
    //           toast.success('Rest Hours Upload Successfully !', {
    //             position: 'top-right',
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: 'light',
    //           })
    //           setData([])

    //           const ele = document.getElementById('fileUpload')
    //           ele.value = ''
    //           setSubmitClick(false)
    //         },
    //         (err) => {
    //           console.log('err', err)
    //           toast.error('Something went wrong !', {
    //             position: 'top-right',
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: 'light',
    //           })
    //           setSubmitClick(false)
    //         },
    //       )
    //     }
    //   },
    //   (err) => {
    //     toast.error("Someting went wrong !")

    //   },
    // )

    try {

      // if (data.vesseladminsignature !== null && data.vesseladminsignature !== undefined) {
      //   const binaryString = atob(data.vesseladminsignature)
      //   const byteArray = new Uint8Array(binaryString.length)
      //   for (let i = 0; i < binaryString.length; i++) {
      //     byteArray[i] = binaryString.charCodeAt(i)
      //   }
      //   const blob = new Blob([byteArray], { type: 'image/png' })
      //   const fileName = new Date().getTime() + 'sign.png'
      //   const file = new File([blob], fileName, { type: 'image/png' })
      //   console.log('file', file)
      //   console.log('fileName', fileName)

      //   await generateSignUrlApi({ file_name: fileName }).then(
      //     async (res) => {
      //       console.log('res', res)

      //       await axios.put(res.data.data.response.url, file).then(
      //         (sres) => {
      //           console.log('sres', sres)
      //           data.vesseladminsignature = res.data.data.response.filename
      //         },
      //         (serr) => {
      //           console.log('serr', serr)
      //           toast.error('Failed saving vesel admin signature!', {
      //             position: 'top-right',
      //             autoClose: 2000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined,
      //             theme: 'light',
      //           })
      //         },
      //       )
      //     },
      //     (err) => {
      //       console.log('err', err)
      //       toast.error('Failed saving vessel admin signature!', {
      //         position: 'top-right',
      //         autoClose: 2000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: 'light',
      //       })
      //     },
      //   )
      // } else {
      //   toast.error('Vessel admin signature not available!', {
      //     position: 'top-right',
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'light',
      //   })
      // }
      delete data.applicationtype;
      data.vesseladminsignature = ""


      const updatedData = [];
      for (let i = 0; i < data.records.length; i++) {
        let item = data.records[i];
        let obj = {};
        obj.name = item.name;
        obj.vessel_admin_name = item.vessel_admin_name;
        obj.vessel_admin_id = item.vessel_admin_id ? item.vessel_admin_id : "NA"
        item.name = obj;
        updatedData.push(item);
        // if (item.signature.startsWith("https")) {
        //   updatedData.push(item);
        //   console.log("item", i, item)

        // }
        // else if (item.signature !== undefined && item.signature !== null) {

        //   const binaryString = atob(item.signature)
        //   const byteArray = new Uint8Array(binaryString.length)
        //   for (let i = 0; i < binaryString.length; i++) {
        //     byteArray[i] = binaryString.charCodeAt(i)
        //   }
        //   const blob = new Blob([byteArray], { type: 'image/png' })
        //   const fileName = new Date().getTime() + 'sign.png'
        //   const file = new File([blob], fileName, { type: 'image/png' })
        //   console.log('file', file)
        //   console.log('fileName', fileName)
        //   try {

        //     const response1 = await generateSignUrlApi({ file_name: fileName });

        //     const image_data = await axios.put(response1.data.data.response.url, file);
        //     console.log("image_data", image_data);

        //     if (image_data.status == 200) {
        //       const requestData = response1.data.data.response.filename;
        //       item.signature = requestData;

        //     }
        //     else {
        //       item.signature = "";
        //     }

        //     obj.vessel_admin_signature = item.vessel_admin_signature;
        //     item.name = obj;

        //     updatedData.push(item);
        //   } catch (error) {
        //     console.error('Error saving signature', error);

        //   }
        // } else {
        //   console.log('Signature not present !');
        // }
      }


      console.log("updatedData", updatedData)




      const reqPayload = {
        vesselid: data.vesselid,
        clientid: data.clientid,
        vesselname: data.vesselname,
        vesseladminname: data.vesseladminname,
        vesseladminusername: data.vesseladminusername,
        vesseladminsignature: data.vesseladminsignature,
        month: data.month,
        year: data.year,
        records: updatedData

      }
      console.log('reqPayload', reqPayload)


      postRestHoursApi(reqPayload).then(
        (res) => {
          console.log('res', res)
          toast.success('Rest Hours Upload Successfully !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          setData([])
          setLoading(false);
          const ele = document.getElementById('fileUpload')
          ele.value = ''

        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          setLoading(false);
          const ele = document.getElementById('fileUpload')
          ele.value = ''
        },
      )


    } catch (error) {

      toast.error("Someting went wrong !", {
        autoClose: 1000
      })

      setLoading(false);

      const ele = document.getElementById('fileUpload')
      ele.value = ''


    }








  };

  async function upploadjson() {
    setLoading(true);



    if (data.applicationtype == "Secondary") {
      toast.error("Please upload JSON file from primary desktop application !", {
        autoClose: 2000,
      })
      setLoading(false);
      const ele = document.getElementById('fileUpload')
      ele.value = ''
      return

    };
    console.log("selected", selectedFile);
    console.log("filename", selectedFile.name);


    const timestamp = new Date().getTime(); // Milliseconds since January 1, 1970 (Unix timestamp)
    console.log(timestamp);
    let fileName = `${timestamp}.json`;

    await generateSignUrlApi({ folderName: "rest-hour-data", file_name: fileName }).then(
      async (res) => {
        console.log('res', res)

        await axios.put(res.data.data.response.url, selectedFile).then(
          (sres) => {
            console.log('sres', sres);
            const name = fileName;

            const accessToken = localStorage.getItem('token')


            const headers = {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            };
            const reqPayload = {
              file_name: name
            }
            axios.post(url, reqPayload, { headers }).then(
              (res) => {
                console.log('res', res)
                toast.success('Rest Hours Upload Successfully !', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                })
                //setData([])
                setLoading(false);
                const ele = document.getElementById('fileUpload')
                ele.value = ''

              },
              (err) => {
                console.log('err', err)
                toast.error('Something went wrong !', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                })
                setLoading(false);
                const ele = document.getElementById('fileUpload')
                ele.value = ''
              },
            )


          },
          (serr) => {
            console.log('serr', serr)
            toast.error('Failed saving rest hour data !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          })


      })


  }

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate()
  }

  function searchRestHourOfUser() {
    // console.log('months', months)


    if (selectVessel == "") {
      toast.error('Please select vessel !', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }

    if (selectedUser.length < 1 || selectedDate.length < 1) {
      toast.error('Date and username is required !', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      setLoader(true)
      console.log('selectedDate', selectedDate)

      const currentMonth = months[selectedDate.getMonth()]
      const currentYear = selectedDate.getFullYear()

      console.log('currentMonth', currentMonth)
      console.log('currentYear', currentYear)
      console.log('selectedUser', selectedUser)

      getRestHoursApi({
        customerId: loginData.user_id,
        month: currentMonth,
        year: currentYear,
        username: selectedUser,
        vesselid: selectVessel
      }).then(
        (res) => {
          console.log('res', res)
          if (res.data.data.response.results.length > 0) {
            let month_number = months.indexOf(currentMonth)
            console.log('month_number', month_number)
            let totalDays = getDaysInMonth(month_number + 1, currentYear)
            console.log('totalDays', totalDays)
            //month = month_number + 1
            //console.log(month_number, month);

            dataArray = []
            setDaysHour([])
            let hour = []
            for (var i = 1; i <= 24; i++) {
              hour.push(
                <th colSpan="2" key={i}>
                  {addzero(i)}
                </th>,
              )
            }
            setHours(hour)

            let data =
              res.data.data.response.results[res.data.data.response.results.length - 1].data
            
          if(isObject(data)){
            data=data.Primary.data
          }    

            console.log('data', data)
            setSelectedUserData(
              res.data.data.response.results[res.data.data.response.results.length - 1],
            )
            //console.log(data);
            for (var j = 0; j < data.length; j++) {
              //let date_hours = dates[j].hours;
              let date_hours = data[j].hours
              //console.log(date_hours);
              let total_hours = 0
              for (var k = 0; k < date_hours.length; k++) {
                if (date_hours[k].first_half == 1) {
                  total_hours++
                }
                if (date_hours[k].second_half == 1) {
                  total_hours++
                }
              }
            }
            console.log('data', data)
            dataArray = data
            generateHtml(dataArray, month_number + 1, currentYear)
          } else {
            setLoader(false)
            setHours([])
          }
        },
        (err) => {
          console.log('err', err)
          setLoader(false)
        },
      )
    }
  }
  function isObject(value) {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }
  const generateHtml = (dateArray, selected_month, currentYear) => {
    let data = dateArray
    //console.log(monthInt);

    var lastSevenHourRest = 0
    let days = []
    for (var j = 0; j < data.length; j++) {
      lastSevenHourRest = getSevenDaysRests(j, data, selected_month, currentYear)
      console.log(lastSevenHourRest)
      //let date_hours = dates[j].hours;
      let date_hours = data[j].hours
      let hour_htmls = []
      //console.log(date_hours);
      let total_hours = 0
      for (var k = 0; k < date_hours.length; k++) {
        if (date_hours[k].first_half == 1) {
          total_hours++
        }
        if (date_hours[k].second_half == 1) {
          total_hours++
        }
        hour_htmls.push(
          <td key={j + '@#' + k + '@#1'}>
            <input class="form-check-input"
              disabled
              type="checkbox"
              defaultValue={j + '@#' + k}
              defaultChecked={date_hours[k].first_half == 1}

            />
            {/* <CFormCheck
              disabled
              type="checkbox"
              defaultValue={j + '@#' + k}
              defaultChecked={date_hours[k].first_half == 1}
              onChange={(e) => getDateHourSlot(e, 1)}
            /> */}
          </td>,
        )
        hour_htmls.push(
          <td key={j + '@#' + k + '@#2'}>
            <input class="form-check-input"
              disabled
              type="checkbox"
              defaultValue={j + '@#' + k}
              defaultChecked={date_hours[k].second_half == 1}

            />
            {/* <CFormCheck
              disabled
              type="checkbox"
              defaultValue={j + '@#' + k}
              defaultChecked={date_hours[k].second_half == 1}
              onChange={(e) => getDateHourSlot(e, 2)}
            /> */}
          </td>,
        )
      }
      hour_htmls.push(<td key={'total_hours_' + j}>{total_hours / 2}</td>)

      var total_work_hours = 24 - Number(total_hours / 2)

      hour_htmls.push(<td>{total_work_hours > 0 ? total_work_hours : ''}</td>)
      hour_htmls.push(<td>{data[j].lastSevenHourRest}</td>)

      hour_htmls.push(
        <td key={j + '@#' + k + '@#3'}>
          <input
            type="text"
            defaultValue={data[j].comment}
            data-row_no={j}
            readOnly
          // onChange={(e) => setComment(e)}
          />
        </td>,
      )
      let loop_date = '2023-' + selected_month + '-' + (j + 1)
      var d = new Date(loop_date)
      console.log('d', d)
      var dayName = smallWeekDays[d.getDay()]
      //console.log(dayName);
      days.push(
        <tr key={j}>
          <th>
            {ordinal_suffix_of(j + 1)} ({dayName})
          </th>
          {hour_htmls}
        </tr>,
      )
      lastSevenHourRest = 0
    }
    //setDataArray(data);
    setDaysHour(days)
    setLoader(false)
  }

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100
    if (j == 1 && k != 11) {
      return i + 'st'
    }
    if (j == 2 && k != 12) {
      return i + 'nd'
    }
    if (j == 3 && k != 13) {
      return i + 'rd'
    }
    return i + 'th'
  }

  const getSevenDaysRests = (index, data, selected_month, currentYear) => {
    if (index > 7) {
      var total_rest_hours = 0
      for (var i = index; i > index - 7; i--) {
        total_rest_hours += Number(dataArray[i].total_rest_hours)
      }
      return total_rest_hours
    } else {
      var total_rest_hours = 0
      for (var j = 0; j < index; j++) {
        total_rest_hours += dataArray[j].total_rest_hours
      }
      var remaining_days = 7 - index

      let month_number = months.indexOf(months[selected_month])
      console.log('month_number', month_number)
      let totalDays = getDaysInMonth(month_number, currentYear)
      for (var k = totalDays; k < totalDays - remaining_days; k--) {
        total_rest_hours += data[k].total_rest_hours
      }

      return Number(total_rest_hours)
    }
  }





  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="uploadresthours">
        <div className="card">
          <div className="card-header poppins-bold bg-primary-blue" style={{ fontSize: '20px' }}>
            Import Rest Hours
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="p-3 " style={{ border: '1px solid #c6c6c6', borderRadius: '8px' }}>
                  <div className='d-flex justify-content-between'>
                    <input
                      type="file"
                      id="fileUpload"
                      accept="application/JSON"
                      onChange={(e) => onFileChange(e)}
                    />
                    {loading ?
                      <button className="btn btn-primary-blue" disabled>
                        <Spinner animation="border" variant="light" role="status" size='sm'>

                        </Spinner>
                        {`Upload`}
                      </button>
                      : <button className="btn btn-primary-blue ms-5" onClick={() => upploadjson()}>
                        Upload
                      </button>
                    }

                  </div>

                  {/* {Object.keys(data).length > 0 ? (
                    submitClick ? (
                      <button disabled={true} className="btn btn-primary-blue ms-2">
                        Upload{' '}
                        <CSpinner className="me-1" component="span" size="sm" aria-hidden="true" />
                      </button>
                    ) : (
                      <button className="btn btn-primary-blue ms-5" onClick={() => uploadData()}>
                        Upload
                      </button>
                    )
                  ) : null} */}
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="card my-4">
          <div className="card-header poppins-bold bg-primary-blue" style={{ fontSize: '20px' }}>
            View Rest Hours
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <label className="poppins-bold">Select Month And Year</label>
                    <DatePicker
                      className="form-control"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      placeholderText="Select Month & Year"
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <label className="poppins-bold">Please Select Vessel</label>

                    <select className='form-control' onChange={(e) => {
                      console.log("val", e.target.value)
                      setSelectVessel(e.target.value);
                    }}>
                      <option>Select Vessel</option>
                      {vesselList.data.map((item) => {
                        return <option value={item.id}>{item.name}</option>
                      })}

                    </select>

                  </div>

                  <div className="col-12 col-md-3">
                    <label className="poppins-bold">Enter Username</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSelectedUser(e.target.value)}
                      value={selectedUser}
                      placeholder="Enter Username"
                    />
                  </div>


                  <div className="col-12 col-md-3">
                    <label className="poppins-bold invisible d-block">Submit</label>
                    <button className="btn btn-primary-blue" onClick={() => searchRestHourOfUser()}>
                      Submit
                    </button>
                  </div>
                </div>

                <div className="col-12">
                  {loader ? (
                    "loading"
                    // <CSpinner className="me-1" component="span" size="sm" aria-hidden="true" />
                  ) : hours.length > 0 ? (
                    <div
                      className="p-0 mt-4 table-responsive "
                      style={{ overflowX: 'scroll', maxHeight: '70vh' }}
                    >
                      <table
                        // id="rest-table"
                        className="table table-striped table-bordered"
                        style={{ width: '100%' }}
                      >
                        <thead>
                          <tr
                            style={{
                              position: 'sticky',
                              top: 0,
                              backgroundColor: 'white',
                              zIndex: '100',
                            }}
                          >
                            <th>HOURS</th>
                            {hours}
                            <th>Total rest hours in 24 hrs period</th>
                            <th>Hours of work as applicable in any 24-hr period</th>
                            <th>Hours of rest as applicable in any 7-day period</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>{daysHour}</tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center mt-4">
                      <p className="text-danger">Data not available</p>
                    </div>
                  )}
                </div>
              </div>

              {daysHour.length > 0 ? (
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-primary-blue"
                    onClick={() => navigate('/easyrest/report', { state: selectedUserData })}
                  >
                    View Report
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UploadRestHours
