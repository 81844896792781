import React, { useEffect, useRef, useState } from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faFileAlt,
  faShip,
  faCloudUpload,
  faTruck,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../../assets/images/project/Easy Rest Logo.svg';
import logo2 from "../../assets/images/project/vedam2.png";
import "../../App.css"
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";

function Sidebar() {
  const [toggle, setToggle] = useState(false);
  let navigate = useNavigate();
  let url = useLocation();
  //console.log("url", url);
  // let toggle = false;




  function reSize() {
    var screenWidth = window.innerWidth;

    if (screenWidth > 768) {
      // Add margin to the div for small screens
      var div1 = document.getElementById("root_body");
      var div2 = document.getElementById("navbar");
      if (toggle == true) {
        div1.style.marginLeft = "64px";
        div2.style.marginLeft = "64px";
      } else {
        div1.style.marginLeft = "240px";
        div2.style.marginLeft = "240px";
      }
    } else {
      // No margin needed for larger screens
    }
  }



  return (
    <>

      <SideNav
        onSelect={(selected) => {
          console.log(selected)
          navigate(selected);
        }}
        onToggle={() => {
          reSize();
          // toggle = !toggle;
          setToggle(!toggle);
        }}
      >
        <SideNav.Toggle />
        <div className={toggle ? "" : "text-center"}>
          <img src={logo} id="logo" className="p-1" alt="logo" height={toggle ? 100 : 50} />
        </div>
        <SideNav.Nav defaultSelected={url.pathname}>

          <NavItem eventKey="/easyrest/vessels">

            <NavIcon >
              <Tooltip
                placement="left"
                overlay={<span>Vessels</span>}
              >
               <FontAwesomeIcon style={{ color: '#e9e9e9', }} icon={faShip} size="lg" />
              </Tooltip>

             


            </NavIcon>

            <NavText >Vessels </NavText>
          </NavItem>
          <NavItem eventKey="/easyrest/vesseladminmaster">
            <NavIcon >
            <Tooltip
                placement="left"
                overlay={<span>Vessel Admin</span>}
              >
              <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faUser} size="lg" />
              </Tooltip>

            

            </NavIcon>
            <NavText>Vessel Admin</NavText>
          </NavItem>
          <NavItem eventKey="/easyrest/resthours">
            <NavIcon >

            <Tooltip
                placement="left"
                overlay={<span>Rest Hours</span>}
              >
              <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faCloudUpload} size="lg" />
              </Tooltip>
             



            </NavIcon>
            <NavText>Rest Hours</NavText>
          </NavItem>
          <NavItem eventKey="/easyrest/consolidatedreport">
            <NavIcon >

            <Tooltip
                placement="left"
                overlay={<span>Report</span>}
              >
              <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faFileAlt} size="lg" />
              </Tooltip>

            </NavIcon>
            <NavText>Report</NavText>
          </NavItem>
        </SideNav.Nav>
        {toggle && <footer
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            padding: "10px",
            backgroundColor: "sky-blue",
            color: "white"
          }}
        >
          <small>Powered By

            <img src={logo2} id="logo" className="p-1" alt="logo" height={30} width={100} />
          </small>
        </footer>
        }
      </SideNav>


    </>
  );
}

export default Sidebar;
