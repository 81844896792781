import React, { useState, useEffect } from 'react'
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import { useNavigate, useLocation } from 'react-router-dom'
import restHours from '../../Common/sampleJson/restHours'
import Poppins from '../../../src/assets/fonts/Poppins/Poppins-Regular.ttf'
import PoppinsBold from '../../../src/assets/fonts/Poppins/Poppins-SemiBold.ttf'
import { ArrowLeftCircle } from 'react-feather'
import Spinner from 'react-bootstrap/esm/Spinner'
import axios from 'axios'

function Report() {
  let navigate = useNavigate()
  let location = useLocation()

  const [restData, setRestData] = useState(location.state)
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  // const [restData, setRestData] = useState(restHours)
  const url = `${process.env.REACT_APP_CONSOLIDATED_PDF}`;
  const [loader, setLoader] = useState(false)
  const [pdfUrl, setPdfData] = useState(null);
  const [down, setDown] = useState(false)
  useEffect(() => {
    console.log('location', location);
    console.log('restHours', restHours);
    console.log('loginData', loginData);

    setLoader(true);

    let token = localStorage.getItem('token')
    const base64 = btoa(token);

    let payload = {
      auth: base64,
      customerId: loginData.user_id,
      month: location.state.month,
      year: location.state.year,
      vesselid: location.state.vesselid,
      username: location.state.username,
      logo:loginData.logo
    }


    axios.post(url, payload, { responseType: "arraybuffer" }).then(
      (res) => {
        console.log("res", res);

        if (res.status === 200) {

          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // const pdfData = new Blob([res.data], { type: 'application/pdf' });
          const pdfData = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(pdfData);
         
         // setTimeout(()=>{
            setPdfData(pdfUrl);
            setLoader(false);
  
            setDown(true)
        //  },2000)
        }

      },
      (err) => {

        setLoader(false);
      }
    );




  }, []);



  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12" >
          <div className="card mb-4">
            <div className="card-header bg-primary-blue" style={{ fontSize: '20px' }}>
              {/* <ArrowLeftCircle
                style={{ cursor: 'pointer' }}
                className=" me-2"
                onClick={() => {
                  navigate(-1)
                }}
              /> */}
              Report
            </div>
            <div className='m-3 d-flex justify-content-start'>
              {loader ? (
                <>
                 <Spinner color="primary" component="span" aria-hidden="true" size='sm' /> ...Loading
                </>
               
              ) :
                <>

                  <iframe src={pdfUrl} width="100%" height="700px"></iframe>

                </>
              }
            </div>


            <div className="d-flex justify-content-end">
              {
                down && <button
                  className="btn btn-primary-blue m-3"
                // onClick={handleclick}
                >
                  {"Download Report"}
                </button>
              }
            </div>



          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Report
