import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast, ToastContainer } from 'react-toastify'
import * as yup from 'yup'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeftCircle, Eye, EyeOff } from 'react-feather'
import { useNavigate } from 'react-router-dom';
import {passwordResetApi} from "../../Common/Api/index"
const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(8, 'Minimum 8 characters required')
      .max(16, 'Maximum 16 characters required')
      .required('Username is required !'),
    cpassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  .required()

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  let navigate = useNavigate()
  const { register, handleSubmit, control, formState, reset, watch } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: { username: 'techadmin@technicious.in', password: 'Tech@123' },
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  const onSubmit = (data) => {
    console.log('data', data)

    const payload = {
      password: data.password,
    }

    passwordResetApi(payload).then(
      (res) => {
        console.log('res', res)
        toast.success('Password reset successfully, please login again !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        reset()
        localStorage.clear()
        setTimeout(() => {
          navigate('/applogin', { replace: true })
        }, [2000])
      },
      (err) => {
        console.log('err', err)
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="row mt-2" style={{ position: 'relative' }}>
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-header bg-primary-blue poppins-bold" style={{ fontSize: '20px' }}>
              <ArrowLeftCircle
                style={{ cursor: 'pointer' }}
                className=" me-2"
                onClick={() => {
                  navigate(-1, { replace: true })
                }}
              />{' '}
              Reset Password
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-control border-0 p-0">
                    <label className="poppins-bold">New Password</label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        {...register('password')}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter your password"
                      />
                      <div className="input-group-append">
                        {showPassword ? (
                          <span className="input-group-text">
                            <EyeOff
                              size={18}
                              className="cursor-pointer my-1"
                              onClick={() => setShowPassword(false)}
                            />
                          </span>
                        ) : (
                          <span className="input-group-text">
                            <Eye
                              size={18}
                              className="cursor-pointer my-1"
                              onClick={() => setShowPassword(true)}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    <span className="text-danger">{errors.password?.message}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 p-0 m-0"></div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-control border-0 p-0">
                    <label className="poppins-bold">Confirm Password</label>

                    <div className="input-group">
                      <input
                        className="form-control"
                        {...register('cpassword')}
                        type={showPassword2 ? 'text' : 'password'}
                        placeholder="Enter your password again"
                      />

                      <div className="input-group-append">
                        {showPassword2 ? (
                          <span className="input-group-text">
                            <EyeOff
                              size={18}
                              className="cursor-pointer my-1"
                              onClick={() => setShowPassword2(false)}
                            />
                          </span>
                        ) : (
                          <span className="input-group-text">
                            <Eye
                              size={18}
                              className="cursor-pointer my-1"
                              onClick={() => setShowPassword2(true)}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    <span className="text-danger">{errors.cpassword?.message}</span>
                  </div>
                  <div className="mt-3 d-flex justify-content-end">
                    <button className="btn btn-primary-blue w-100" onClick={handleSubmit(onSubmit)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
