import React, { useState, useEffect } from "react";
import { Edit, Trash2, Eye, EyeOff, Download, Archive, ArrowLeftCircle, PlusCircle } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Spinner from "react-bootstrap/Spinner";
import Pagination from 'react-bootstrap/Pagination';
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import {
  DelCustomerApi,
  getVesselAdminsApi,
  getVesselApi,
  createVesselAdminsApi,
  delVesselAdminsApi,
  updateVesselAdminApi,
  getVesselUsersApi,
  createVesseluserApi,
  generateSignUrlApi
} from "../../Common/Api/index";
import EditVesselUser from "./EditVesselUser";
import { calMaxPage } from "../../Common/Functions/CommonFunctions";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";


const apiurl = process.env.REACT_APP_API_URL;

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(50, "50 characters allowed !")
      .required("Name is required !"),
    rank: yup.string().required("Please select rank !"),
    email: yup
      .string()
      .required("Email is required !")
      .email("Please enter valid email !"),
    username: yup.string().required("Username is required !"),
    //vessel: yup.string().required("Rank is required !"),
    //adminEmail: yup.string().required('Email is required !').email('Please enter valid email !'),
    // password: yup
    //   .string()
    //   .required("Password is required !")
    //   .min(6, "Minimum 6 characters required !")
    //   .max(16, "Maximum 16 characters allowed !")
    //   .matches(/^((?!\s).)*$/, "Space not allowed"),

    // signature: yup.mixed()
    //   .test(
    //     'fileSize',
    //     'Signature  is required, Max size is 5MB !',
    //     (value) => !value || (value && value[0]?.size <= 5 * 1024 * 1024)
    //   )
    //   .required('Signature  is required'),
  })
  .required();
const data_rank = [
  {
    "id": 1,
    "name": "Master"
  },
  {
    "id": 2,
    "name": "Additional Master"
  },
  {
    "id": 3,
    "name": "Chief Officer"
  },
  {
    "id": 4,
    "name": "Additional Chief Officer"
  },
  {
    "id": 5,
    "name": "2nd Officer"
  },
  {
    "id": 6,
    "name": "Additional 2nd Officer"
  },
  {
    "id": 7,
    "name": "3rd Officer"
  },
  {
    "id": 8,
    "name": "Chief Engineer"
  },
  {
    "id": 9,
    "name": "2nd Engineer"
  },
  {
    "id": 10,
    "name": "3rd Engineer"
  },
  {
    "id": 11,
    "name": "4th Engineer"
  },
  {
    "id": 12,
    "name": "Trainee Marine Engineer"
  },
  {
    "id": 13,
    "name": "Junior Engineer"
  },
  {
    "id": 14,
    "name": "DPO"
  },
  {
    "id": 15,
    "name": "NWKO"
  },
  {
    "id": 16,
    "name": "Junior Officer"
  },
  {
    "id": 17,
    "name": "Radio Officer"
  },
  {
    "id": 18,
    "name": "Electrical Officer"
  },
  {
    "id": 19,
    "name": "Electronic Officer"
  },
  {
    "id": 20,
    "name": "Trainee Electrical Officer"
  },
  {
    "id": 21,
    "name": "Trainee Electronic Officer"
  },
  {
    "id": 22,
    "name": "Oiler"
  },
  {
    "id": 23,
    "name": "Fitter"
  },
  {
    "id": 24,
    "name": "Bosun"
  },
  {
    "id": 25,
    "name": "AB Seaman"
  },
  {
    "id": 26,
    "name": "Ordinary Seaman"
  },
  {
    "id": 27,
    "name": "Trainee Oiler"
  },
  {
    "id": 28,
    "name": "Cook"
  },
  {
    "id": 29,
    "name": "General Steward"
  },
  {
    "id": 30,
    "name": "Deck Cadet"
  },
  {
    "id": 31,
    "name": "Welder"
  },
  {
    "id": 32,
    "name": "Wiper"
  },
  {
    "id": 33,
    "name": "Trainee Seaman"
  }
]



function VesselUserMaster() {

  let navigate = useNavigate();
  let location = useLocation();

  const [visible, setVisible] = useState(false);
  const [users, setUsers] = useState({ data: [], loading: false });
  const [sameAsEmail, setSameAsEmail] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({
    maxPage: "",
    totalCount: "",
  });



  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail) {
      const email = getValues("email");
      setValue("username", email);
    }
  }, [sameAsEmail]);

  useEffect(() => {
    if (visible == true) {
      reset();
    }
  }, [visible]);

  useEffect(() => {
    fetchUsers(page);

  }, []);

  async function downloadUserJSON(val) {
    const loginData = JSON.parse(localStorage.getItem('loginData'))

    val.temp_password = "abcd1234";
    val.reset_password = "false";
    console.log('val', val)
    val.customerId = loginData.user_id
    if (Object.keys(val).length > 0) {
      const json = await JSON.stringify(val)
      // console.log('json', json)
      const blob = new Blob([json], { type: 'application/json' })
      const url = URL.createObjectURL(blob)
      const fileName = val.name + '_' + val.vesselname + '.json'
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      URL.revokeObjectURL(url)
    } else {
      return null
    }
  }




  const fetchUsers = (p, ves, searchVal) => {
    setUsers({ ...users, data: [], loading: true });

    const params = { limit: 10, offset: p - 1, search: searchVal, vesselid: location.state.vesselid };
    if (ves) {
      console.log("ves", ves);
    }

    getVesselUsersApi
      (params).then(
        (res) => {
          console.log("ressd", res);
          if (res.data.data.statusCode == 200) {
            // let users_data=res.data.data.response.results.filter((item)=>item.vesselid==location.state.vesselid)
            // console.log("user_data",users_data)
            setMaxPageAndTotalCount({
              ...maxPageAndTotalCount,
              maxPage: calMaxPage(res.data.data.response.count.totalData),
              totalCount: res.data.data.response.count.totalData,
            });
            if (res.data.data.response.results.length > 0) {
              setUsers({
                ...users,
                loading: false,
                data: res.data.data.response.results,
              });
            } else {
              setUsers({ ...users, loading: false, data: [] });
            }
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const onSubmit = async(data) => {
    console.log("data", data);
    if (users.data.some(user => user.email === data.email)) {
      toast.error(`Email ${data.email} is already exists !`, {
        autoClose: 1000
      })
      return
    }
    if (users.data.some(user => user.username === data.username)) {
      toast.error(`Username ${data.username} is already exists !`, {
        autoClose: 1000
      })
      return
    }

    if (data !== "") {
      data.vesselid = location.state.vesselid;
      data.vesselname = location.state.vesselName;
      // data.temp_password= "abcd1234";
      // data.reset_password= "false";
      data.password = "12345"
      console.log(data, "data");
      if(data.signature.length>0){
        try {
          const payload=data.signature[0];
          const response1 = await generateSignUrlApi({ file_name: payload.name });
  
          const image_data = await axios.put(response1.data.data.response.url, payload);
          console.log("image_data", image_data);
  
          if (image_data.status == 200) {
            const requestData = response1.data.data.response.filename;
            data.signature = requestData;
  
          }
          else {
            data.signature = "";
          }
  
          
          console.log("data",data)
          createVesseluserApi(data).then(
            (res) => {
              console.log("res", res);
              toast.success("Vessel User created successfully", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
    
              setVisible(false);
              reset();
              fetchUsers(1);
            },
            (err) => {
              console.log("err", err);
              toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          );
  
          
  
  
  
  
        } catch (error) {
          console.log("errr",error);
  
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
      else{
        data.signature=""
        createVesseluserApi(data).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel User created successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
  
            setVisible(false);
            reset();
            fetchUsers(1);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }





     
    } else {
      errors.showMessages();
    }
  };

  function delVesselAdmin(val) {
    SweetAlert.fire({
      title: "Are you sure to delete? \n" + val.name,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        console.log(val);
        console.log(val.id);
        console.log(val.created);
        console.log(
          "process.env.REACT_APP_APP_ID",
          process.env.REACT_APP_APP_ID
        );
        delVesselAdminsApi({ id: val.id, created: val.created }).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel User deleted successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            fetchUsers(page);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
    });
  }



  function softDelete(data) {
    const payload = data;
    const params = {
      id: data.id,
    };
    console.log("payload", payload);
    // console.log('props.data', props.data)

    payload.vesselid = payload.vesselid;
    payload.vesselname = payload.vesselname;

    delete payload.vessel;
    console.log("params", params);
    payload.isactive = false;
    SweetAlert.fire({
      title: `Are you sure you want to Deactivate this ${data.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        updateVesselAdminApi(payload, params).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel user Deactivated successfully !", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            fetchUsers(page);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
    });
  }

  return (
    <div className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="card">
        <div
          className="card-header poppins-bold bg-primary-blue"
          style={{ fontSize: "20px" }}
        >
          <ArrowLeftCircle
            style={{ cursor: 'pointer' }}
            className=" me-2"
            onClick={() => {
              navigate(-1, { replace: true })
            }}
          />{' '}
          Vessel User List
        </div>
        <div className="card-body">
          <div className="row justify-content-md-between">
            <div className="col-12 col-md-4">

            </div>

            <div className="col-12 col-md-3">
              <label className="poppins-bold invisible">Search</label>
              <button
                className="btn btn-primary-blue w-100"
                onClick={() => {
                  setVisible(!visible);
                  setSameAsEmail(false);
                }}
              >
                Create User
              </button>
            </div>
          </div>
          <div className="mt-4">
            {users.loading ? (
              <Spinner color="primary" />
            ) : users.data.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped table-bordered mt-4">
                  <thead>
                    <tr className="poppins-bold">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Email</th>
                      <th scope="col">Username</th>
                      <th scope="col">Signature</th>
                      <th scope="col">Vessel</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.data.map((val, i) => (
                      <tr key={i}>
                        <td>{i + srNo + 1}</td>
                        <td>{val.name}</td>
                        <td>{val.rank == undefined ? "NA" : val.rank}</td>
                        <td>{val.email}</td>
                        <td>{val.username}</td>
                        <td>
                          {val.signature !== null &&val.signature!==""?(
                            <img src={val.signature} height={'60'} width={'60'} alt="Signature" />
                          ) : (
                            'NA'
                          )}
                        </td>
                        <td>{val.vesselname}</td>
                        <td>{val.isactive == true ? "Active" : "Inactive"}</td>
                        <td>
                          <EditVesselUser
                            data={val}
                            // fetchVessels={fetchVessels}
                            fetchUsers={fetchUsers}
                            vesselName={location.state.vesselName}
                            vesselid={location.state.vesselid}
                            users={users.data.filter((item) => item.username !== val.username)}
                            page={page}
                          />


                          <Tooltip
                            placement="bottom"
                            overlay={<span>
                              Delete Vessel User</span>}
                          >
                            <Trash2
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => delVesselAdmin(val)}
                            />
                          </Tooltip>


                          {val.isactive && <Tooltip
                            placement="bottom"
                            overlay={<span>Download JSON</span>}
                          >
                            <Download
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => downloadUserJSON(val)}
                            />
                          </Tooltip>}


                          {val.isactive && <Tooltip
                            placement="bottom"
                            overlay={<span>
                              Deactivate Vessel User</span>}
                          >
                            <Archive
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => softDelete(val)}
                            />
                          </Tooltip>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="m-0 text-center text-danger">{"No Data Found !"}</p>
            )}

            {users.data.length > 0 ? (
              <div className="mt-4">
                <Pagination
                  className=" cursor_pointer justify-content-end pagination-primary"
                  aria-label="Page navigation example"
                >
                  <Pagination.Item
                    onClick={() => {
                      fetchUsers(page - 1)
                      setPage((prevP) => prevP - 1)
                      setSrNo((prevC) => prevC - 10)
                    }}
                    disabled={page === 1 ? true : false}
                  >
                    Previous
                  </Pagination.Item>
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      fetchUsers(page + 1)
                      setPage((prevP) => prevP + 1)
                      setSrNo((prevC) => prevC + 10)
                    }}
                    disabled={
                      page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                        ? true
                        : false
                    }
                  >
                    Next
                  </Pagination.Item>
                </Pagination>
                <div>
                  <div className="d-flex justify-content-end">
                    <p
                      className="mb-0"
                      style={{ fontSize: '14px' }}
                    >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p
                      className=""
                      style={{ fontSize: '14px' }}
                    >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal show={visible} onHide={() => setVisible(false)} size="lg">
        <form className="">
          <Modal.Header className="bg-primary-blue " closeButton>
            <Modal.Title>Create Vessel User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Name*"
                    name="name"
                    {...register('name')}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Rank'}</label>
                  {/* <input
                    className={errors.rank ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Rank*"
                    name="rank"
                    {...register('rank')}
                  /> */}
                  <select className="form-control"
                    {...register('rank')}
                  >
                    <option value={""} >{"Select"}</option>
                    {data_rank.map((item) => {
                      return (

                        <option value={`${item.name}`}>{item.name}</option>)
                    })}
                  </select>

                  <span className="text-danger">{errors.rank?.message}</span>
                </div>
              </div>



              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Email'}</label>
                  <input
                    className={errors.email ? 'form-control is-invalid' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register('email')}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Username'}</label>
                  <input
                    className={errors.username ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="username"
                    placeholder="Username*"
                    {...register('username')}
                  />
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      checked={sameAsEmail}
                      onChange={(e) => setSameAsEmail(e.target.checked)}
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      style={{ fontSize: '13px', color: 'gray' }}
                      className="form-check-label"
                      htmlFor="exampleCheck1"
                    >
                      Same As Email
                    </label>
                  </div>
                  <span className="text-danger">{errors.username?.message}</span>
                </div>
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Select Vessel'}</label>

                  
                  <Controller
                    name="vessel"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={vesselList.data}
                        loading={vesselList.loading}
                        //   isLoading={locations.state}
                        placeholder="Select Vessel"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.vessel ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.vessel?.message}</span>
                </div>
              </div> */}

              {/* <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Password'}</label>
                  <div className="input-group">
                    <input
                      className={errors.password ? 'form-control is-invalid' : 'form-control'}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password*"
                      name="password"
                      {...register('password')}
                    />
                    <div className="input-group-append">
                      {showPassword ? (
                        <span className="input-group-text">
                          <EyeOff
                            size={18}
                            className="cursor-pointer my-1"
                            onClick={() => setShowPassword(false)}
                          />
                        </span>
                      ) : (
                        <span className="input-group-text">
                          <Eye
                            size={18}
                            className="cursor-pointer my-1"
                            onClick={() => setShowPassword(true)}
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  <span className="text-danger">{errors.password?.message}</span>
                </div>
              </div> */}

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">Signature</label>

                  <input
                    className={errors.signature ? 'form-control is-invalid' : 'form-control'}
                    type={'file'}
                    accept="image/*"
                    {...register('signature')}
                  />

                  {/* <span className="text-danger">{errors.signature?.message}</span> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary-blue"
              onClick={handleSubmit(onSubmit)}
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default VesselUserMaster;
