import React from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import '../App.css';

export const Party_address_master = () => {
    const [data, setData]=useState({});
  
    const handleChage=(e)=>{
      const {id,value}=e.target; 
      setData({...data,[id]:value});
    }
  
    const submit=(e)=>{
      e.preventDefault();
      console.log("asdnf",data)
    }
  return (
    <div className="">
      <p className="text-center mt-3 poppins-bold">Create a Address</p>

      <Form>
        <Row>
        <Col md={4}>
            <Form.Group controlId="partyId">
              <Form.Label> Party Id</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }}/>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="addressType">
              <Form.Label>Address type</Form.Label>
              <Form.Control type="text"  onChange={(e)=>{
                handleChage(e);
              }}/>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
        
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }}/>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="pan">
              <Form.Label>PAN</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <Button variant="primary" type="submit" onClick={submit}>
              Submit
            </Button>
            
          </Col>
        </Row>
      </Form>
    </div>
  )
}
