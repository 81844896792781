import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "../App.css";

const schema = yup
  .object()
  .shape({
    company_name: yup
      .string()
      .matches(
        /^[A-Za-z0-9&]+$/,
        "Company name must only contain letters, numbers, and ampersands !"
      )
      .min(3, "Company name must be at least 3 characters long !")
      .max(100, "Company name must be no more than 100 characters long !"),
    gstin: yup
      .string()
      .min(15, "GST number must be 15 characters long !")
      .max(15, "GST number must be 15 characters long !")
      .matches(
        /^([0-9]{2}[0-9A-Z]{13})$/,
        "GST number must only contain letters and numbers !"
      ),
    address: yup
      .string()
      .min(5, "Address must be at least 5 characters long !")
      .max(255, "Address must be no more than 255 characters long !"),
    pan: yup
      .string()
      .min(10, "PAN must be at least 10 characters long !")
      .max(10, "PAN must be at least 10 characters long !")
      .matches(
        /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
        "PAN number must only contain letters and numbers !"
      ),
    city: yup.string(),
    state: yup.string(),
    faxNo: yup
      .string()
      .min(10, "Fax number must be at least 10 characters long !")
      .max(15, "Fax number must be no more than 15 charaters long !")
      .matches(
        /^([0-9]{10}|[0-9]{3}-[0-9]{3}-[0-9]{4})$/,
        "Enter valid Fax number !"
      ),
    country: yup.string(),
    postalCode: yup
      .string()
      .min(5, "Postal code must be at least 5 characters long !")
      .max(10, "Postal code must be no more than 10 characters long !"),
    //.matches(/^([A-Z]{2}[0-9]{3}|[0-9]{5}|[A-Z]{2}[0-9]{3}[A-Z]{1})$/,"Enter valid postal code !"),
    website: yup.string().url(),
  })
  .required();
function Party_type_master() {
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;
  const [data, setData] = useState({});

  const handleChage = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    console.log("asdnf", data);
  };

  const onsubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <div style={{ color: "#344454" }}>


        <div className="row g-5">
          <div className="col-12 col-md-5">
            <div className="form-group mb-4">
              <label className="poppins-bold">{"Company Name"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                placeholder="Company Name*"
                name="company_name"
                {...register("company_name")}
              />

              <span className="text-danger">
                {errors.company_name?.message}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="form-group mb-4">
              <label className="poppins-bold">{"GST Number"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                placeholder="Enter GST Number"
                name="gstin"
                {...register("gstin")}
              />

              <span className="text-danger">{errors.gstin?.message}</span>
            </div>
          </div>
        </div>

        <div className="row g-5">
          <div className="col-12 col-md-5">
            <div className="form-group mb-4">
              <label className="poppins-bold">{"Address"}</label>
              <textarea
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text-area"
                placeholder="Enter Address"
                name="address"
                rows="1"
                cols="33"
                {...register("address")}
              />

              <span className="text-danger">{errors.address?.message}</span>
            </div>
          </div>

          <div className="col-12 col-md-5">
            <div className="form-group mb-4">
              <label className="poppins-bold">{"PAN Number"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text-area"
                placeholder="Enter PAN Number"
                name="pan"
                {...register("pan")}
              />

              <span className="text-danger">{errors.pan?.message}</span>
            </div>
          </div>
        </div>

        <div className="row g-5">
          <div className="col-12 col-md-5">
            <div className="row">
              <div className="col-12 col-md-6">
                {" "}
                <div className="form-group mb-4">
                  <label className="poppins-bold">{"City"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Enter city "
                    name="city"
                    {...register("city")}
                  />

                  <span className="text-danger">{errors.city?.message}</span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {" "}
                <div className="form-group mb-4">
                  <label className="poppins-bold">{"State"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="State"
                    name="state"
                    {...register("state")}
                  />

                  <span className="text-danger">{errors.state?.message}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            {" "}
            <div className="form-group mb-4">
              <label className="poppins-bold">{"Fax Number"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                placeholder="Fax Number*"
                name="faxNo"
                {...register("faxNo")}
              />

              <span className="text-danger">{errors.faxNo?.message}</span>
            </div>
          </div>
        </div>

        <div className="row g-5">
          <div className="col-12 col-md-5">
            <div className="row">
              <div className="col-12 col-md-6">
                {" "}
                <div className="form-group mb-4">
                  <label className="poppins-bold">{"Country"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Enter country "
                    name="country"
                    {...register("country")}
                  />

                  <span className="text-danger">{errors.country?.message}</span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {" "}
                <div className="form-group mb-4">
                  <label className="poppins-bold">{"Postal Code"}</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    type="text"
                    placeholder="Postal Code "
                    name="postalCode"
                    {...register("postalCode")}
                  />

                  <span className="text-danger">
                    {errors.postalCode?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            {" "}
            <div className="form-group mb-4">
              <label className="poppins-bold">{"Website"}</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                type="text"
                placeholder="Webstie"
                name="website"
                {...register("website")}
              />

              <span className="text-danger">{errors.website?.message}</span>
            </div>
          </div>
        </div>



        <div className="row mt-2" >
          <div className="col-12 col-md-3 ms-auto px-0 d-flex justify-content-end">
            <button style={{ fontSize: '18px' }} className="btn btn-grey px-4" onClick={handleSubmit(onsubmit)}>Edit</button>
            <button style={{ fontSize: '18px' }} className="btn btn-yellow ms-4 px-4" onClick={handleSubmit(onsubmit)}>Update</button>
          </div>
        </div>
      </div>

    </>
  );
}

export default Party_type_master;
