import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import '../App.css';

function Party_contact_master() {
  const [data, setData]=useState({});
  
  const handleChage=(e)=>{
    const {id,value}=e.target; 
    setData({...data,[id]:value});
  }

  const submit=(e)=>{
    e.preventDefault();
    console.log("asdnf",data)
  }
  return (
    <div className="">
      <p className="text-center mt-3 poppins-bold">Create a Contact</p>

      <Form>
        <Row>
        <Col md={4}>
            <Form.Group controlId="partyId">
              <Form.Label> Party Id</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }}/>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text"  onChange={(e)=>{
                handleChage(e);
              }}/>  
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="designation">
              <Form.Label>Designation</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="department">
              <Form.Label>Dapartment</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="emailId">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="mobileNo">
              <Form.Label> Mobile No</Form.Label>
              <Form.Control type="number" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="altMobileNo">
              <Form.Label>Alt. Mobile No</Form.Label>
              <Form.Control type="number" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="landlineNo">
              <Form.Label>Landline No</Form.Label>
              <Form.Control type="number" onChange={(e)=>{
                handleChage(e);
              }}/>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="IIExtention">
              <Form.Label>IIExtention</Form.Label>
              <Form.Control type="text" onChange={(e)=>{
                handleChage(e);
              }} />
            </Form.Group>
          </Col>
         
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <Button variant="primary" type="submit" onClick={submit}>
              Submit
            </Button>
            
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Party_contact_master;
