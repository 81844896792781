import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Typeahead } from 'react-bootstrap-typeahead'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal';
import { createVesselApi, getVesselTypeApi } from '../../Common/Api/index'

const schema = yup
  .object()
  .shape({
    vesselIMONO: yup.string()
    .required('IMO no is required ')
    .test('match', 'Please enter valid IMO number', function (value) {
      return /^[1-9]\d*$/.test(value)}),
    uniquenumber: yup
      .string()
      //.required('Call sign no is required !')
      .typeError('Only numbers allowed'),
    // vesselIMONO: yup.string().required('Vessel IMO No is required !'),
    // vesselIMONO: yup.string().required('Vessel IMO No is required !').matches(/^[0-9]*$/, "Enter valid IMO NO !"),
    vesselName: yup
      .string()
      .max(50, '50 characters allowed !')
      .required('Vessel name is required !'),
    vesselType: yup
      .array()
      .min(1, 'Please select vessel type !')
      .nullable()
      .required('Please select vessel type !'),
    GT: yup
      .string()
      .test('match', 'Please enter valid GT', function (value) {
        return /^(?!0)\d*(\.\d+)?$/.test(value); // Matches positive decimal numbers not starting with zero
      }),
     // .required('GT is required !')
     // .matches(/^[0-9]*$/, 'Enter valid GT !'),
  //  vesselInfo: yup.string().notRequired(),
    // status: yup
    //   .string()
    //   .nullable()
    //   .required('Status is required !')
    //   .matches(/^((?!Select).)*$/, 'Please select valid option'),
  })
  .required()

var FormData = require('form-data')

function CreateVesselForm(props) {
  const [visible, setVisible] = useState(false)
  const [vesselTypeList, setVesselTypeList] = useState({ data: [], loading: false })
  const loginData = JSON.parse(localStorage.getItem('loginData'))

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  useEffect(() => {
    console.log('visible', visible)
    if (visible == true) {
      fetchVesselTypes()
      reset()
    }
  }, [visible])

  function fetchVesselTypes() {
    setVesselTypeList({ ...vesselTypeList, data: [], loading: true })
    getVesselTypeApi().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          setVesselTypeList({
            ...vesselTypeList,
            data: res.data.data.response.results,
            loading: false,
          })
        } else {
          setVesselTypeList({ ...vesselTypeList, data: [], loading: false })
        }
      },
      (err) => {
        console.log('err', err)
      },
    )
  }

  const onSubmit = (data) => {
    if (data !== '') {
      console.log('data', data)

      const reqPayload = {
        number: data.vesselIMONO,
        uniquenumber: data.uniquenumber,
        type_id: data.vesselType[0].id,
        name: data.vesselName,
        gt: data.GT,
        status: true ,
        customerId: loginData.user_id,
      }
      console.log('reqPayload', reqPayload)

      createVesselApi(reqPayload).then(
        (res) => {
          console.log('res', res)
          toast.success('Vessel created successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })

          setVisible(false)
          props.fetchVessels(1)
          reset()
        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
      )
    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      
      <button
        onClick={() => setVisible(!visible)}
        style={{ fontSize: '16px', maxHeight: '40px' }}
        className="btn btn-primary-blue"
      >
        Create New Vessel
      </button>
      <Modal show={visible} onHide={() => setVisible(false)} size='lg'>
        <Modal.Header className='bg-primary-blue' closeButton>
          <Modal.Title >Create New Vessel</Modal.Title>
        </Modal.Header>
        <Modal.Body><form className="">
          <div className="row">


            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{'IMO No'}</label>
                <input
                  className={
                    errors.vesselIMONO ? 'form-control is-invalid f-16' : ' f-16 form-control'
                  }
                  type="text"
                  placeholder="Enter IMO No*"
                  name="vesselIMONO"
                  {...register('vesselIMONO')}
                />

                <span className="text-danger">{errors.vesselIMONO?.message}</span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{'Call Sign'}</label>
                <input
                  className={
                    errors.uniquenumber ? 'form-control is-invalid f-16' : ' f-16 form-control'
                  }
                  type="text"
                  placeholder="Enter Call Sign"
                  name="uniquenumber"
                  {...register('uniquenumber')}
                />

                <span className="text-danger">{errors.uniquenumber?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{'Vessel Name'}</label>
                <input
                  className={
                    errors.vesselName ? 'form-control is-invalid f-16' : ' f-16 form-control'
                  }
                  type="text"
                  placeholder="Enter vessel name*"
                  name="vesselName"
                  {...register('vesselName')}
                />

                <span className="text-danger">{errors.vesselName?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{'Vessel Type'}</label>

                <Controller
                  name="vesselType"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="name"
                      multiple={false}
                      options={vesselTypeList.data}
                      isLoading={vesselTypeList.loading}
                      placeholder="Select Vessel Type*"
                      onChange={onChange}
                      onBlur={onBlur}
                      // selected={value}
                      isInvalid={errors.vesselType ? true : false}
                    />
                  )}
                />

                <span className="text-danger">{errors.vesselType?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{'GT'}</label>
                <input
                  className={errors.GT ? 'form-control is-invalid f-16' : ' f-16 form-control'}
                  type="text"
                  placeholder="Enter GT*"
                  name="GT"
                  {...register('GT')}
                />

                <span className="text-danger">{errors.GT?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                {/* <label className="poppins-bold">{'Status'}</label>

                <Controller
                  name="status"
                  control={control}
                  defaultValue={true}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      defaultValue={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      className={
                        errors.status
                          ? 'custom-select form-control is-invalid f-16'
                          : ' f-16 custom-select form-control'
                      }
                    >
                      <option>{'Select'}</option>
                      <option value={true}>{'Active'}</option>
                      <option value={false}>{'Inactive'}</option>
                    </select>
                  )}
                />

                <span className="text-danger">{errors.status?.message}</span> */}
              </div>
            </div>
          </div>
        </form></Modal.Body>
        <Modal.Footer>

          <button className='btn btn-primary-blue ' onClick={handleSubmit(onSubmit)}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  )
}

export default CreateVesselForm
