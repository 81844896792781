import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import logo2 from '../../assets/images/project/Easy Rest Logo.svg'
// import background from "../../../assets/images/login_back.jpg";
import man from '../../assets/images/project/user.png'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast, ToastContainer } from 'react-toastify'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { login } from '../../Common/Api/constants'
import Spinner from 'react-bootstrap/Spinner';
import {Eye,EyeOff} from "react-feather"

const schema = yup
  .object()
  .shape({
    username: yup.string().required('Username is required !'),
    password: yup.string().required('Password is required !'),
  })
  .required()

function Login() {

  const [value, setValue] = useState(localStorage.getItem('profileURL' || man))
  const [loginClick, setLoginClick] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    // console.log('userInfo', userInfo)
    if (value !== null) localStorage.setItem('profileURL', value)
    else localStorage.setItem('profileURL', man)
  }, [value])


  const { register, handleSubmit, control, formState, reset, watch } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: { username: 'techadmin@technicious.in', password: 'Tech@123' },
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  const onSubmit = (data) => {
    setLoginClick(true)
    //console.log('data', data)
    const payload = {
      username: data.username,
      password: data.password,
      application: process.env.REACT_APP_APP_ID,
    }
    const apiurl = process.env.REACT_APP_API_URL + login
    axios.post(apiurl, payload).then(
      (res) => {
        console.log('res', res)
        if (res.data.data.statusCode == 200) {
          if (res.data.data.response.role == 'customer_admin') {
            localStorage.setItem('token', res.data.data.response.token)
            const data = res.data.data.response
            delete data.token
            localStorage.setItem('loginData', JSON.stringify(res.data.data.response))
            navigate(`/easyrest/vessels`)
          } else {
            toast.error('Invalid User', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            setLoginClick(false)
          }
        }
      },
      (err) => {
        console.log('err', err)
        if (err.response.status == 400) {
          setLoginClick(false)
          toast.error(err.response.data.data.response, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      },
    )
  }

  return <React.Fragment>
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />

    <div className="app_login" style={{ marginTop: '50px' }}>
      <div className="container">
        <div className="row ">
          <div className="col-md-12 ">
            <div className="auth-innerright">
              <div className="authentication-box ">
                <div className="text-center">
                  <img src={logo2} width={'180px'} height={'150px'} alt="" />
                  {/* <h4 className="pt-3">{"Opti Heat"}</h4> */}
                </div>
                <div className="card mt-4 shadow-lg mx-auto" style={{ maxWidth: '600px' }}>
                  <div className="card-body">
                    <div className="text-center">
                      <h4>{'Sign In'}</h4>
                      <h6>{'Enter Office Admin Username and Password'} </h6>
                      {/* <h6 className="f-22">{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"} </h6> */}
                    </div>
                    <form>
                      <div className="form-group">
                        <label className="col-form-label poppins-bold pt-0">{'Username'}</label>
                        <input
                          className="form-control"
                          type="text"
                          name="username"
                          // value={email}
                          // onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter username"
                          {...register('username')}
                        />
                        <span className="text-danger">{errors.username?.message}</span>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label poppins-bold">{'Password'}</label>
                       <div
                       className="input-group"
                       >


                       <input
                          className="form-control"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          // value={password}
                          // onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter password"
                          {...register('password')}
                        />

                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: 'pointer' }}
                          >
                            {showPassword ? <Eye /> : <EyeOff />}
                          </span>
                        </div>
                       </div>

                        <span className="text-danger">{errors.password?.message}</span>
                      </div>

                      <div className="form-group form-row mt-4 mb-0 d-grid">
                        {loginClick ? (
                          <button className="btn btn-primary-blue" disabled>
                            <Spinner animation="border" variant="light" role="status" size='sm'>
                              {/* <span className="visually-hidden">Loading...</span> */}
                            </Spinner>
                            {` Sign In`}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary-blue"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                          >
                            {'Sign In'}
                          </button>
                        )}
                        <p
                          className="forget_pass ms-auto"
                          style={{ fontSize: '14px', color: '#2264ab' }}
                          onClick={() => navigate('/forgotpassword')}
                        >
                          Forgot Password
                        </p>
                        <div className="mt-2 text-center">
                          <p className="m-0" style={{ fontSize: '14px' }}>
                            Version {process.env.REACT_APP_VERSION}
                          </p>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default Login;
