import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Eye, EyeOff } from 'react-feather'

import { Typeahead } from 'react-bootstrap-typeahead'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import { updateVesselAdminApi, getVesselApi, updateVesseluserApi, generateSignUrlApi } from '../../Common/Api/index'
import { downloadAsJSON } from '../../Common/Functions/CommonFunctions'
import { Edit, Trash2 } from 'react-feather'
import axios from 'axios'

const schema = yup
  .object()
  .shape({
    name: yup.string().max(50, '50 characters allowed !').required('Name is required !'),
    rank: yup.string().max(50, '50 characters allowed !').required('Please select rank !'),
    email: yup.string().required('Email is required !').email('Please enter valid email !'),
    username: yup.string().required('Username is required !'),
    status: yup
      .string()
      .nullable()
      .required('Status is required !')
      .matches(/^((?!Select).)*$/, 'Please select valid option')
  })
  .required()

var FormData = require('form-data')

function EditVesselAdmin(props) {
    const data_rank=[
        {
          "id": 1,
          "name": "Master"
        },
        {
          "id": 2,
          "name": "Additional Master"
        },
        {
          "id": 3,
          "name": "Chief Officer"
        },
        {
          "id": 4,
          "name": "Additional Chief Officer"
        },
        {
          "id": 5,
          "name": "2nd Officer"
        },
        {
          "id": 6,
          "name": "Additional 2nd Officer"
        },
        {
          "id": 7,
          "name": "3rd Officer"
        },
        {
          "id": 8,
          "name": "Chief Engineer"
        },
        {
          "id": 9,
          "name": "2nd Engineer"
        },
        {
          "id": 10,
          "name": "3rd Engineer"
        },
        {
          "id": 11,
          "name": "4th Engineer"
        },
        {
          "id": 12,
          "name": "Trainee Marine Engineer"
        },
        {
          "id": 13,
          "name": "Junior Engineer"
        },
        {
          "id": 14,
          "name": "DPO"
        },
        {
          "id": 15,
          "name": "NWKO"
        },
        {
          "id": 16,
          "name": "Junior Officer"
        },
        {
          "id": 17,
          "name": "Radio Officer"
        },
        {
          "id": 18,
          "name": "Electrical Officer"
        },
        {
          "id": 19,
          "name": "Electronic Officer"
        },
        {
          "id": 20,
          "name": "Trainee Electrical Officer"
        },
        {
          "id": 21,
          "name": "Trainee Electronic Officer"
        },
        {
          "id": 22,
          "name": "Oiler"
        },
        {
          "id": 23,
          "name": "Fitter"
        },
        {
          "id": 24,
          "name": "Bosun"
        },
        {
          "id": 25,
          "name": "AB Seaman"
        },
        {
          "id": 26,
          "name": "Ordinary Seaman"
        },
        {
          "id": 27,
          "name": "Trainee Oiler"
        },
        {
          "id": 28,
          "name": "Cook"
        },
        {
          "id": 29,
          "name": "General Steward"
        },
        {
          "id": 30,
          "name": "Deck Cadet"
        },
        {
          "id": 31,
          "name": "Welder"
        },
        {
          "id": 32,
          "name": "Wiper"
        },
        {
          "id": 33,
          "name": "Trainee Seaman"
        }
      ]
  const [visible, setVisible] = useState(false)
  const loginData = JSON.parse(localStorage.getItem('loginData'))

  const [vesselList, setVesselList] = useState({ data: [], loading: false })


  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState

  useEffect(() => {
    console.log('props.data', props.data)
    if (visible == true) {
     reset()
      //setValue('vessel', [{ id: props.data.vesselid, name: props.data.vesselname }])
    }
  }, [visible])

  

  const onSubmit =async (data) => {




    if (data !== '') {
      const payload = data
      const params = {
        id: props.data.id,
      }
      if( props.users.some(user => user.email === data.email)){
        toast.error(`Email ${data.email} is already exists !`,{
          autoClose:1000
        })
        return
      }
      if( props.users.some(user => user.username === data.username)){
        toast.error(`Username ${data.username} is already exists !`,{
          autoClose:1000
        })
        return
      }


      console.log('payload', payload)
      console.log('props.data', props.data)

      payload.vesselid = props.vesselid
      payload.vesselname =props.vesselName
     
      console.log('params', params)
      console.log("payload",payload)

      payload.isactive=data.status=="false"?false:true
      delete payload.status
      // updateVesselAdminApi(payload, params).then(
      //   (res) => {
      //     console.log('res', res)
      //     toast.success('Vessel User updated successfully', {
      //       position: 'top-right',
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: 'light',
      //     })
      //     setVisible(!visible)
      //     props.fetchUsers(props.page)
      //   },
      //   (err) => {
      //     console.log('err', err)
      //     toast.error('Something went wrong', {
      //       position: 'top-right',
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: 'light',
      //     })
      //   },
      // )

      if(data.signature.length==0){

        data.signature=props.data.signature;
       updateVesselAdminApi(payload, params).then(
         (res) => {
           console.log('res', res)
           toast.success('Vessel User updated successfully', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                })
                setVisible(!visible)
                props.fetchUsers(props.page)
         },
         (err) => {
           console.log('err', err)
           toast.error('Something went wrong', {
             position: 'top-right',
             autoClose: 2000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: 'light',
           })
         },
       )
     }
     else{
       try {
         const sign_payload=data.signature[0];
         const response1 = await generateSignUrlApi({ file_name: sign_payload.name });
 
         const image_data = await axios.put(response1.data.data.response.url, sign_payload);
         console.log("image_data", image_data);
 
         if (image_data.status == 200) {
           const requestData = response1.data.data.response.filename;
           payload.signature = requestData;
 
         }
         else {
           payload.signature = "";
         }
 
         
          console.log("payload",payload)
 
 
         updateVesselAdminApi(payload, params).then(
           (res) => {
             console.log('res', res)
             toast.success('Vessel User updated successfully', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  })
                  setVisible(!visible)
                  props.fetchUsers(props.page)
           },
           (err) => {
             console.log('err', err)
             toast.error('Something went wrong', {
               position: 'top-right',
               autoClose: 2000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: 'light',
             })
           },
         )
 
 
 
 
       } catch (error) {
         console.log("errr",error);
 
         toast.error("Something went wrong", {
           position: "top-right",
           autoClose: 2000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
         });
       }
 
 
 
     }
    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />



      <Edit size={18} className="cursor-pointer " onClick={() => setVisible(!visible)} />

      <Modal show={visible} onHide={() => setVisible(false)} size='lg'>
        <form className="">
          <Modal.Header className="bg-primary-blue " closeButton>
            <Modal.Title>Update Vessel User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Name*"
                    name="name"
                    {...register('name')}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Rank'}</label>
                  {/* <input
                    className={errors.rank ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Rank*"
                    name="rank"
                    {...register('rank')}
                    defaultValue={props.data?.rank}
                  /> */}
                  <select className="form-control"
                    {...register('rank')}
                    defaultValue={props.data.rank}
                  >
                    {data_rank.map((item) => {
                      return (<option value={`${item.name}`}>{item.name}</option>)
                    })}

                  </select>

                  <span className="text-danger">{errors.rank?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Email'}</label>
                  <input
                    className={errors.email ? 'form-control is-invalid' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register('email')}
                    defaultValue={props.data?.email}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Username'}</label>
                  <input
                    className={errors.username ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="username"
                    placeholder="Username*"
                    {...register('username')}
                    defaultValue={props.data?.username}
                  />

                  <span className="text-danger">{errors.username?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Status'}</label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={props.data?.isactive}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        defaultValue={props.data?.isactive}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={
                          errors.status
                            ? 'custom-select form-control is-invalid f-16'
                            : ' f-16 custom-select form-control'
                        }
                      >
                        <option>{'Select*'}</option>
                        <option value={true}>{'Active'}</option>
                        <option value={false}>{'Inactive'}</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">Signature</label>

                  <input
                    className={errors.signature ? 'form-control is-invalid' : 'form-control'}
                    type={'file'}
                    accept="image/*"
                    {...register('signature')}
                  />

                  <span className="text-danger">{errors.signature?.message}</span>
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary-blue" onClick={handleSubmit(onSubmit)}>
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  )
}

export default EditVesselAdmin
